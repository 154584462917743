<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Calculate Rates</h2>
    </div>
    <div class="grid grid-cols-12 gap-2 mt-5 intro-y box p-5">
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Select Worker Type</label>
            <TomSelect
                v-model="rate_data.worker_type" @change="getWorkerNdProcess" class="w-full"
                >
              <option value="1">Employee</option>
              <option value="2">Manager</option>
              <option value="3">Job Party</option>
              <option value="4">Inward Party</option>
            </TomSelect>
            <div class="text-primary-3" v-if="page_data.errors.worker_type">{{ page_data.errors.worker_type[0] }}</div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Select Process</label>
          <TomSelect
              v-model="rate_data.process_id" class="w-full" @change="updateProcessType"
              >
              <option value="0">Select Process</option>
              <option v-for="process in page_data.process_list" :key="process.id" :value="process.id">{{ process.process_name }} ({{ process.process_type }})</option>
          </TomSelect>
          <div class="text-primary-3" v-if="page_data.errors.process_id">{{ page_data.errors.process_id[0] }}</div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4" v-if="rate_data.worker_type != 1">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Select Worker</label>
          <TomSelect
              v-model="rate_data.worker_list" class="w-full" multiple
              >
              <option value="0">Select Worker</option>
              <option v-for="item in page_data.worker_list" :key="item.id" :value="item.user_id">{{ (item.first_name ? item.first_name : '-') + ' ' + (item.last_name ? item.last_name : '') }}</option>
          </TomSelect>
          <div class="text-primary-3" v-if="page_data.errors.worker_list">{{ page_data.errors.worker_list[0] }}</div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4 hidden md:block" v-else>
        <div class="mt-1">
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Weight (From)</label>
          <div>

            <input
              type="test"
              class="form-control py-2"
              v-model="rate_data.from"
              placeholder="Weight (From)"
              aria-describedby="input-group-3" @keypress="$h.preventInvalidInput"
            />
          </div>
          <div class="text-primary-3" v-if="page_data.errors.from">{{ page_data.errors.from[0] }}</div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Weight (To)</label>
          <div>

            <input
              type="test"
              class="form-control py-2"
              v-model="rate_data.to"
              placeholder="Weight (To)"
              aria-describedby="input-group-3" @keypress="$h.preventInvalidInput"
            />
          </div>
          <div class="text-primary-3" v-if="page_data.errors.to">{{ page_data.errors.to[0] }}</div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Rate</label>
          <div>

            <input
              type="test"
              class="form-control py-2"
              v-model="rate_data.rate"
              placeholder="Rate"
              aria-describedby="input-group-3" @keypress="$h.preventInvalidInput"
            />
          </div>
          <div class="text-primary-3" v-if="page_data.errors.rate">{{ page_data.errors.rate[0] }}</div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Select Rate Type</label>
          <TomSelect
              v-model="rate_data.rate_type"
              id="crud-form-2" disabled
              :options="{
                placeholder: 'Select your Labour'
              }"
              class="w-full"
            >
              <option value="1">Issue Weight</option>
              <option value="2">Fixed Piece</option>
              <option value="3">Weight + Weight</option>
              <option value="4">Piece + Piece</option>
              <option value="5">Weight Loss</option>
            </TomSelect>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">Start Date</label>
          <Litepicker
            v-model="rate_data.s_date"
            :options="{
              autoApply: true,
              showWeekNumbers: true,
              dropdowns: {
                minYear: new Date().getFullYear() - 5,
                maxYear: ((new Date().getFullYear())),
                maxDate: (new Date()).getDate() - 1,
                months: true,
                years: true,
              },
            }"
            class="form-control block mx-auto"
          />
          <div class="text-primary-3" v-if="page_data.errors.s_date">{{ page_data.errors.s_date[0] }}</div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1">End Date</label>
          <Litepicker
            v-model="rate_data.e_date"
            :options="{
              autoApply: true,
              showWeekNumbers: true,
              dropdowns: {
                minYear: new Date().getFullYear() - 5,
                maxYear: ((new Date().getFullYear())),
                maxMonth: ((new Date().getMonth())),
                maxDate: 25,
                months: true,
                years: true,
              },
            }"
            class="form-control block mx-auto"
          />
          <div class="text-primary-3" v-if="page_data.errors.e_date">{{ page_data.errors.e_date[0] }}</div>
        </div>
      </div>
      <div class="col-span-12">
        <!-- BEGIN: Input -->
        <div id="input">
          <div class="preview">
            <div class="grid grid-cols-12 gap-6">

              <div class="intro-y col-span-12" v-if="rate_data.worker_type == 2 || rate_data.worker_type == 3">
                <div class="flex items-center mt-3">
                  <div class="form-check">
                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="rate_data.type">
                    <label class="form-check-label inline-block text-lg" for="flexRadioDefault1">
                      Standard Rates
                    </label>
                  </div>
                  <div class="form-check ml-6">
                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="rate_data.type">
                    <label class="form-check-label inline-block text-lg" for="flexRadioDefault2">
                      Fixed Per Piece
                    </label>
                  </div>
                  <div class="form-check ml-6" v-if="rate_data.worker_type == 2">
                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="3" type="radio" name="flexRadioDefault" id="flexRadioDefault3" v-model="rate_data.type">
                    <label class="form-check-label inline-block text-lg" for="flexRadioDefault3">
                      % by Employee Rate
                    </label>
                  </div>
                </div>
              </div>
              <div class="intro-y col-span-12 md:col-span-6">
                <div class="mt-3" v-if="['makeable'].includes(rate_data.process_type)">
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                    <TomSelect
                      v-model="rate_data.color"
                          :options="{
                            placeholder: 'Select Colors'
                          }"
                          class="w-full"
                        multiple
                        >
                      <option value="All">All</option>
                      <option v-for="col in page_data.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
                    </TomSelect>
                  </div>
                  <div class="text-primary-3" v-if="page_data.errors.color">{{ page_data.errors.color[0] }}</div>
                </div>
                <div class="mt-3" v-if="['makeable'].includes(rate_data.process_type)">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                      <TomSelect
                        v-model="rate_data.purity"
                          :options="{
                            placeholder: 'Select Purity'
                          }"
                          class="w-full"
                        multiple
                        >
                      <option value="All">All</option>
                      <option v-for="col in page_data.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
                    </TomSelect>
                  </div>
                <div class="text-primary-3" v-if="page_data.errors.purity">{{ page_data.errors.purity[0] }}</div>
                </div>
                <div class="mt-3" v-if="['makeable'].includes(rate_data.process_type)">
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                    <TomSelect
                      v-model="rate_data.shape"
                          :options="{
                            placeholder: 'Select your favorite actors'
                          }"
                          class="w-full"
                        multiple
                        >
                      <option value="All">All</option>
                      <option v-for="col in page_data.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}</option>
                    </TomSelect>
                  </div>
                  <div class="text-primary-3" v-if="page_data.errors.shape">{{ page_data.errors.shape[0] }}</div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                    <TomSelect
                      v-model="rate_data.stone"
                          :options="{
                            placeholder: 'Select your favorite actors'
                          }"
                          class="w-full"
                        multiple
                        >
                      <option value="All">All</option>
                      <option v-for="col in page_data.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                    </TomSelect>
                  </div>
                  <div class="text-primary-3" v-if="page_data.errors.stone">{{ page_data.errors.stone[0] }}</div>
                </div>
              </div>
              <div class="intro-y col-span-12 md:col-span-6">
                <div class="mt-3" v-if="['makeable'].includes(rate_data.process_type)">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                    <TomSelect
                      v-model="rate_data.cut"
                          :options="{
                            placeholder: 'Select Cut'
                          }"
                          class="w-full"
                        multiple
                        >
                      <option value="All">All</option>
                      <option v-for="col in page_data.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
                    </TomSelect>
                  </div>
                  <div class="text-primary-3" v-if="page_data.errors.cut">{{ page_data.errors.cut[0] }}</div>
                </div>
                <div class="mt-3" v-if="['makeable'].includes(rate_data.process_type)">
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                    <TomSelect
                      v-model="rate_data.polish"
                          :options="{
                            placeholder: 'Select your favorite actors'
                          }"
                          class="w-full"
                        multiple
                        >
                      <option value="All">All</option>
                      <option v-for="col in page_data.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
                    </TomSelect>
                  </div>
                  <div class="text-primary-3" v-if="page_data.errors.polish">{{ page_data.errors.polish[0] }}</div>
                </div>
                <div class="mt-3" v-if="['makeable'].includes(rate_data.process_type)">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                    <TomSelect
                      v-model="rate_data.symmetry"
                          :options="{
                            placeholder: 'Select Symmetry'
                          }"
                          class="w-full"
                        multiple
                        >
                      <option value="All">All</option>
                      <option v-for="col in page_data.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
                    </TomSelect>
                  </div>
                  <div class="text-primary-3" v-if="page_data.errors.symmetry">{{ page_data.errors.symmetry[0] }}</div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-3" class="input-group-text w-36 text-center">Processing</div>
                    <select
                      class="form-select"
                      v-model="rate_data.processing"
                      aria-label="Default select example"
                    >
                      <option value="all">All</option>
                      <option value="normal">Normal</option>
                      <option value="special">Special</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right mt-3">
                  <router-link :to="{ name: 'manual-rate-list' }" class="btn btn-outline-secondary w-24 mr-1">
                    Cancel
                  </router-link>
                  <button type="button" @click="SaveEmployeeRate" :disabled="page_data.submit" class="btn btn-primary">Update Rates
                    <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                  </button>
                </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <div id="superlarge-modal-size-preview2"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5" v-if="page_data.errors.error">{{ page_data.errors.error }}</div>
            </div>
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <!-- <div id="vertical-form"> -->
                <div class="flex flex-col justify-between mt-5">
                  <!-- BEGIN: Data List -->
                  <div class="intro-y col-span-12 overflow-auto lg:overflow-visible" v-if="page_data.errors.prevEmpRate">
                    <table class="w-full">
                      <thead>
                        <tr>
                          <th class="px-1 md:px-1 py-3 text-center">From</th>
                          <th class="px-1 md:px-1 py-3 text-center">To</th>
                          <th class="px-1 md:px-1 py-3 text-center">Rates</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rate_data.process_type)">Color</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rate_data.process_type)">Cut</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rate_data.process_type)">Purity</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rate_data.process_type)">Polish</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="rate_data.process_type == 'makeable'">Shape</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="rate_data.process_type == 'makeable'">Fluerocent</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rate_data.process_type)">Symmetry</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="rate_data.process_type == 'makeable'">Tension</th>
                          <th class="px-1 md:px-1 py-3 text-center">Stone</th>
                          <th class="px-1 md:px-1 py-3 text-center">Start_Date</th>
                          <th class="px-1 md:px-1 py-3 text-center">End_Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="px-1 md:px-1 py-3 text-center font-medium">{{ page_data.errors.prevEmpRate.from }}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium">{{ page_data.errors.prevEmpRate.to }}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium">{{ page_data.errors.prevEmpRate.rates }}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rate_data.process_type)"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.color" :key="typ"><span v-if="page_data.errors.parameters.colors.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rate_data.process_type)"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.cut" :key="typ"><span v-if="page_data.errors.parameters.cuts.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rate_data.process_type)"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.purity" :key="typ"><span v-if="page_data.errors.parameters.purities.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rate_data.process_type)"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.polish" :key="typ"><span v-if="page_data.errors.parameters.polishs.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="rate_data.process_type == 'makeable'"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.shape" :key="typ"><span v-if="page_data.errors.parameters.shapes.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="rate_data.process_type == 'makeable'"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.fluerocent" :key="typ"><span v-if="page_data.errors.parameters.fluerocents.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rate_data.process_type)"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.symmetry" :key="typ"><span v-if="page_data.errors.parameters.symmetries.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="rate_data.process_type == 'makeable'"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.tension" :key="typ"><span v-if="page_data.errors.parameters.tensions.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium"><div class="flex-col items-start" v-for="typ in page_data.errors.prevEmpRate.stone" :key="typ"><span v-if="page_data.errors.parameters.stones.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium whitespace-nowrap">{{ page_data.errors.prevEmpRate.start_time}}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium whitespace-nowrap">{{ page_data.errors.prevEmpRate.end_time}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <!-- END: Data List -->
                </div>
              <!-- </div> -->
            </div>
            <div class="px-5 pb-8 text-right flex items-end">
              <button type="button" class="btn btn-primary w-24 mr-1" :disabled="page_data.submit" @click="updateData"><SaveIcon class="w-4 h-4 mr-1" />
              <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              Update</button>
              <a
                    class="btn btn-primary w-24 mr-1"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#success-modal-preview"
                  >
                    <Edit2Icon class="w-4 h-4 mr-1" /> Edit
                  </a>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
            </div>
            <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import Toastify from 'toastify-js'
import { defineComponent, inject, onMounted, reactive, toRef, watch } from 'vue'
import moment from 'moment'
import cash from 'cash-dom'
import { stringify } from 'json5'
export default defineComponent({
  setup() {

    const route  = useRoute()
    const swal = inject('$swal')
    const page_data = reactive({
      worker_list: [],
      submit: false,
      endis: false,
      process_list: [],
      errors: [],
      success: '',
      colors: [],
      cuts: [],
      purities: [],
      polish: [],
      shapes: [],
      stones: [],
      symmetry: [],
      s_wt: true,
      e_wt: true
    })

    const rate_data = reactive({
      worker_type: '1',
      process_id: '0',
      worker_list: [],
      type: '1',
      from: 0.01,
      to: 0.1,
      rate: '',
      rate_type: '1',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      stone: [],
      tension: [],
      fluerocent: [],
      s_date: '',
      e_date: '',
      processing: 'all',
      process_type: ''
    })


    // const rate_data = reactive({
    //   process: '0',
    //   user_type: 'Employee',
    //   from: 0,
    //   to: 0.01,
    //   rate: '',
    //   rate_type: '1',
    //   color: '',
    //   cut: '',
    //   purity: '',
    //   polish: '',
    //   shape: '',
    //   symmetry: '',
    //   stone: '',
    //   tension: '',
    //   fluerocent: '',
    //   s_date: '',
    //   e_date: '',
    //   processing: 'all',
    //   process_type: 'makeable'
    // })
    onMounted(() => {
      getColorDetails()
      getProcessDetails()
    })
    function getColorDetails() {
        axios({
          url: 'api/master/get_all_parameters',
          method: 'post',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          page_data.cuts = res.data.cut
          page_data.colors = res.data.color
          page_data.purities = res.data.purity
          page_data.polish = res.data.polish
          page_data.shapes = res.data.shape
          page_data.symmetry = res.data.symmetry
          page_data.fluerocents = res.data.fluerocent
          page_data.tensions = res.data.tension
          page_data.stones = res.data.stone
        })
    }
    function getProcessData() {
      if(rate_data.process_type == 'video') {
        rate_data.tension = []
      } else if(['rough', 'bunch'].includes(rate_data.process_type)) {
        rate_data.color = []
        rate_data.cut = []
        rate_data.purity = []
        rate_data.polish = []
        rate_data.shape = []
        rate_data.symmetry = []
        rate_data.tension = []
        rate_data.fluerocent = []
      }
      const params = {
        process_type : rate_data.process_type
      }
      rate_data.process = '0'
      axios({
        url: 'api/master/get_process_by_type',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        page_data.process_list = res.data.items
      })
    }
    function SaveEmployeeRate() {
      if(rate_data.process == '0') {

      } else if(rate_data.process_type) {

        rate_data.s_date = moment(rate_data.s_date).format('YYYY-MM-DD');
        rate_data.e_date = moment(rate_data.e_date).format('YYYY-MM-DD');

        let url = 'api/master/calculate_new_rates'
          page_data.submit = true
          axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: rate_data,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            page_data.submit = false

            if(res.data.status == "error") {

              swal("Warning", res.data.message, "warning");
              return;

            } else {

              swal("Success", res.data.message, "success");
            }
            router.push({ name: 'manual-rate-list' })
          }).catch((err) => {
            page_data.submit = false
            page_data.errors = err.response.data.errors
            setTimeout(() => {
              page_data.errors = []
            }, 5000);
          })

      }
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    function IncrementWeight(type,name) {
      if(name == 'From') {
        if(type == 'increment') {
          rate_data.from = parseFloat((rate_data.from + 0.01).toFixed(2))
         }else {
           rate_data.from = parseFloat((rate_data.from - 0.01).toFixed(2))
         }
      } else if(name == 'To') {
        if(type == 'increment') {
          rate_data.to = parseFloat((rate_data.to + 0.01).toFixed(2))
         }else {
           rate_data.to = parseFloat((rate_data.to - 0.01).toFixed(2))
         }
      }
    }
    function updateData() {
      axios({
        url: 'api/master/add_update_new_process_rate',
        method: 'post',
        baseURL: BASE_URL,
        data: rate_data,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        page_data.submit = false
        page_data.errors.error = ''
        cash('#superlarge-modal-size-preview2').modal('hide')
        cash('#success-modal-preview').modal('hide')
        page_data.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          }, 100);

          setTimeout(() => {
            page_data.success = ''
          }, 5000);
        router.push({ name: 'employee-rates' })
      }).catch(() => {
        page_data.submit = false
      })
    }
    function updateExistingData() {
      router.push({ name: 'update-employee-rates', params: { id: page_data.errors.prevEmpRate.id} })
    }
    function getProcessType() {
      const params = {
        process_id : rate_data.process
      }
      if(rate_data.process != '0') {

        axios({
          url: 'api/master/get_process_type',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          rate_data.rate_type = stringify(res.data.process_type)
        })
      }
    }

    const getWorkerNdProcess = () => {

      // Get Process Details
      // getProcessDetails()
      // Get Worker Details

      if(rate_data.worker_type != 1) {

        rate_data.worker_list = [];
        getWorkerDetails()
      } else {
        page_data.worker_list = [];
        rate_data.worker_list = [];
      }
    }

    const getWorkerDetails = () => {

      // Get Worker Details
      let params = {
        process_id: (rate_data.process_id != '0' ? window.btoa(rate_data.process_id) : ""),
        worker_type: (rate_data.worker_type != '0' ? window.btoa(rate_data.worker_type) : ""),
      }

      let promise = axios({
          url: "api/master/get_worker_by_type",
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          }
      });
      return promise
          .then(result => result.data)
          .then(data => {
            page_data.worker_list = data.items
            rate_data.worker_list = []
          })
          .catch(_error => {
            page_data.worker_list = []
            rate_data.worker_list = []

          });
    }

    const getProcessDetails = () => {

      let params = {

      }

      let promise = axios({
          url: "api/master/dp_process",
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          }
      });
      return promise
          .then(result => result.data)
          .then(data => {
            page_data.process_list = data.items
          })
          .catch(_error => {
            page_data.process_list = []

          });
    }

    const updateProcessType = () => {

      // Update Process Type
      if(rate_data.process_id != '0') {

        page_data.process_list.forEach(element => {

          if(element.id == rate_data.process_id) {

            rate_data.process_type = element.process_type
            rate_data.rate_type = element.transaction_type
          }

        });

      } else {

        rate_data.process_type = ''
        rate_data.rate_type = element.transaction_type
      }

      // Update Process Type
      rate_data.worker_list = [];
      getWorkerDetails()

    }

    watch(() => rate_data.color , (CurrentValue, OldValue) => {
      if(rate_data.color.includes('All')) {
        rate_data.color.splice(0,rate_data.color.length - 1)
        rate_data.color[0] = 'All'
      }
    })
    watch(() => rate_data.cut , (CurrentValue, OldValue) => {
      if(rate_data.cut.includes('All')) {
        rate_data.cut.splice(0,rate_data.cut.length - 1)
        rate_data.cut[0] = 'All'
      }
    })
    watch(() => rate_data.purity , (CurrentValue, OldValue) => {
      if(rate_data.purity.includes('All')) {
        rate_data.purity.splice(0,rate_data.purity.length - 1)
        rate_data.purity[0] = 'All'
      }
    })
    watch(() => rate_data.polish , (CurrentValue, OldValue) => {
      if(rate_data.polish.includes('All')) {
        rate_data.polish.splice(0,rate_data.polish.length - 1)
        rate_data.polish[0] = 'All'
      }
    })
    watch(() => rate_data.shape , (CurrentValue, OldValue) => {
      if(rate_data.shape.includes('All')) {
        rate_data.shape.splice(0,rate_data.shape.length - 1)
        rate_data.shape[0] = 'All'
      }
    })
    watch(() => rate_data.symmetry , (CurrentValue, OldValue) => {
      if(rate_data.symmetry.includes('All')) {
        rate_data.symmetry.splice(0,rate_data.symmetry.length - 1)
        rate_data.symmetry[0] = 'All'
      }
    })
    watch(() => rate_data.stone , (CurrentValue, OldValue) => {
      if(rate_data.stone.includes('All')) {
        rate_data.stone.splice(0,rate_data.stone.length - 1)
        rate_data.stone[0] = 'All'
      }
    })
    watch(() => rate_data.from , (CurrentValue, OldValue) => {
      if(rate_data.from <= 0) {
        page_data.s_wt = true
      } else {
        page_data.s_wt = false
      }
      if(parseFloat(rate_data.to ? rate_data.to : 0) <= parseFloat(rate_data.from ? rate_data.from : 0)) {
        rate_data.to = parseFloat(rate_data.from ? rate_data.from : 0).toFixed(2)
      }
    })
    watch(() => rate_data.to , (CurrentValue, OldValue) => {
      if(parseFloat(rate_data.to ? rate_data.to : 0) <= parseFloat(rate_data.from ? rate_data.from : 0)) {
        rate_data.to = parseFloat(rate_data.to ? rate_data.to : 0).toFixed(2)
        page_data.e_wt = true
      } else {
        page_data.e_wt = false
      }
    })
    return {
      page_data,
      rate_data,
      getProcessData,
      basicNonStickyNotificationPacket,
      SaveEmployeeRate,
      IncrementWeight,
      updateData,
      updateExistingData,
      getProcessType,
      getWorkerNdProcess,
      updateProcessType
    }
  }
})
</script>
