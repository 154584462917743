<template>
<div id="basic-non-sticky-notification-packet-success" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">{{ pagedata.message }}</div>
    </div>
</div>
<div id="basic-non-sticky-notification-packet-error" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
        <div class="font-medium text-black">{{ pagedata.error }}</div>
    </div>
</div>
<div>
    <!-- BEGIN: Data List -->
    <div class="intro-y box overflow-auto p-3 my-5" v-if="pagedata.packets.length > 0">

        <table class="table w-full">
            <thead>
                <tr>
                    <th class="">Pkt No</th>
                    <th class="">Stock No</th>
                    <th class="">Weight</th>
                    <th class="">Exp. Wt</th>
                    <th class="">Shape</th>
                    <th class="">Color</th>
                    <th class="">Purity</th>
                    <th class="">Cut</th>
                    <th class="">Polish</th>
                    <th class="">Symm</th>
                    <th class="">Length</th>
                    <th class="">Width</th>
                    <th class="">Height</th>
                    <th class="">Comment</th>
                    <!-- <th class="">Stn</th> -->
                    <!-- <th class="">Fluc</th> -->
                    <!-- <th class="">Tn</th> -->
                    <!-- <th class="">Discount</th> -->
                    <!-- <th class="">Est Majuri</th> -->
                    <th class="">Status</th>
                    <th class="">
                        <div class="flex items-center justify-end">
                            <!-- <h2 class="intro-y text-lg font-medium mr-5">All Packets of Lot No :  <span class="ml-2 text-primary-1">{{ pagedata.lat_no }}</span></h2> -->
                            <button type="button" class="btn btn-primary btn-sm shadow-md mx-2" v-print="'#print-all-barcods'">
                                <PrinterIcon class="w-4 h-4" /></button>
                            <a href="javascript:;" @click="pagedata.packets = []" class="btn btn-danger btn-sm mx-2">
                                <XIcon class="w-4 h-4" /></a>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(packet, index) in pagedata.packets" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                    <td class="whitespace-nowrap">{{ packet.number }}</td>
                    <td class="whitespace-nowrap">{{ packet.vepari_no ? packet.vepari_no : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.weight ? packet.weight : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.shape ? packet.shape : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.color ? packet.color : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.purity ? packet.purity : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.cut ? packet.cut : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.polish ? packet.polish : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.symmetry ? packet.symmetry : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.length ? $h.myFloat(packet.length ? packet.length : 0) : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.width ? $h.myFloat(packet.width ? packet.width : 0) : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.height ? $h.myFloat(packet.height ? packet.height : 0) : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.comment ? packet.comment : '-' }}</td>
                    <!-- <td class="whitespace-nowrap">{{ packet.stone ? packet.stone : '-' }}</td> -->
                    <!-- <td class="whitespace-nowrap">{{ packet.fluerocent ? packet.fluerocent : '-' }}</td> -->
                    <!-- <td class="whitespace-nowrap">{{ packet.tension ? packet.tension : '-' }}</td> -->
                    <!-- <td class="whitespace-nowrap">{{ packet.discount ? packet.discount : '-' }}</td> -->
                    <!-- <td class="whitespace-nowrap">{{ packet.est_value ? packet.est_value : '-' }}</td> -->
                    <td class="whitespace-nowrap">
                        <div v-if="packet.status == 2">
                            <p class="text-primary-1">In Process</p>
                        </div>
                        <div v-else-if="packet.status == 1">
                            <p class="text-primary-8">Active</p>
                        </div>
                        <div v-else-if="packet.status == 0">
                            <p class="text-primary-6">Hold</p>
                        </div>
                        <div v-else-if="packet.status == 3">
                            <p class="text-primary-3">Cancelled</p>
                        </div>
                        <div v-else-if="packet.status == 6">
                            <p class="text-primary-4">Delivered</p>
                        </div>
                        <div v-else-if="packet.status == 4">
                            <p class="text-primary-4">Blocked</p>
                        </div>
                        <div v-else-if="packet.status == 5">
                            <p class="text-primary-4">Out Word</p>
                        </div>
                    </td>
                    <td class="py-2 font-medium text-center" v-if="[1,2,8].includes(user_details.user_role)">
                        <div class="flex justify-start items-center p-0 m-0">
                          <div>
                      <a class="flex items-center text-theme-1" href="javascript:;" title="Add Comment" data-toggle="modal" @click="AddStoneDetails(packet)" data-target="#add-packet-part-modal">
                          <FileTextIcon class="w-5 h-5 mr-1" />
                      </a>
                  </div>
                            <div>
                                <button class="text-theme-1 mr-3" v-print="'#' + packet.number" v-if="user_details.user_role != 3">
                                    <PrinterIcon class="w-5 h-5" />
                                </button>
                            </div>
                            <!-- <a
                   class="flex items-center text-theme-6 mr-3" v-if="pagedata.access == true && packet.assineaccess == true"
                   href="javascript:;" title="Reject"
                   data-toggle="modal"
                   data-target="#delete-confirmation-modal-single" @click="pagedata.cancelId = packet.id"
                 >
                   <Trash2Icon class="w-5 h-5 mr-1" />
                  </a> -->
                            <div>
                                <a class="flex items-center text-theme-6 mr-3" v-if="(packet.status == 1 || packet.status == 0)" href="javascript:;" title="Reject" data-toggle="modal" data-target="#delete-confirmation-modal-single" @click="pagedata.cancelId = packet.id">
                                    <Trash2Icon class="w-5 h-5 mr-1" />
                                </a>
                            </div>
                            <!-- <a
                   class="flex items-center text-theme-6 mr-3" v-if="packet.status == 0 && pagedata.access != true"
                   href="javascript:;" title="Reject"
                   data-toggle="modal"
                   data-target="#delete-confirmation-modal-single" @click="pagedata.cancelId = packet.id"
                 >
                   <Trash2Icon class="w-5 h-5 mr-1" />
                 </a> -->
                            <div>
                                <a class="flex items-center text-theme-9" v-if="packet.status == 1 && packet.assineaccess == true && packet.manager_id == user_details.account_id" href="javascript:;" title="Assigh Process" data-toggle="modal" @click="SinglePacketAssign(packet.id, packet.number, packet.type)" data-target="#single-process-modal">
                                    <ChevronsRightIcon class="w-6 h-6 mr-1" />
                                </a>
                            </div>
                            <div>
                                <a class="flex items-center text-theme-1" v-if="packet.status == 0 && packet.assineaccess == true" href="javascript:;" title="Assign Process" @click="AddToStock(packet.id)">
                                    A
                                </a>
                            </div>
                            <div class="p-0 m-0 hidden">
                                <div :id="packet.number" class="absolute top-0 left-0 p-0 m-0 mt-2">
                                    <table class="p-0 m-0 w-full text-black" border="collapse">
                                        <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                            <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="packet.mk_weight != null">PW : {{ packet.polish_weight }}</th>
                                        </tr>
                                        <tr class="text-center m-0 p-0">
                                            <th colspan="9" class="text-center">
                                                <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                                            </th>
                                        </tr>
                                        <!-- <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                            <th class="border m-auto border-black">SHP</th>
                                            <th class="border m-auto border-black">CL</th>
                                            <th class="border m-auto border-black">PRT</th>
                                            <th class="border m-auto border-black">CT</th>
                                            <th class="border m-auto border-black">PL</th>
                                            <th class="border m-auto border-black">SYM</th>
                                            <th class="border m-auto border-black">Fl</th>
                                            <th class="border m-auto border-black">TN</th>
                                            <th class="border m-auto border-black">STN</th>
                                        </tr>
                                        <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                            <th class="border m-auto border-black">{{ packet.shape }}</th>
                                            <th class="border m-auto border-black">{{ packet.color }}</th>
                                            <th class="border m-auto border-black">{{ packet.purity }}</th>
                                            <th class="border m-auto border-black">{{ packet.cut }}</th>
                                            <th class="border m-auto border-black">{{ packet.polish }}</th>
                                            <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                            <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                            <th class="border m-auto border-black">{{ packet.tension }}</th>
                                            <th class="border m-auto border-black">{{ packet.stone }}</th>
                                        </tr>
                                        <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                                            <th class="border m-auto border-black" colspan="3">L</th>
                                            <th class="border m-auto border-black" colspan="3">W</th>
                                            <th class="border m-auto border-black" colspan="3">H</th>
                                        </tr>
                                        <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                                            <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.length ? packet.length : 0) }}</th>
                                            <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.width ? packet.width : 0) }}</th>
                                            <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.height ? packet.height : 0) }}</th>
                                        </tr> -->
                                    </table>
                                   <p class="p-0 mt-1 text-black w-full text-right" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">{{packet.batch_no}}</span> / <span class="text-right">FOREVER LGD</span></p>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="hidden">
        <div id="print-all-barcods" class="absolute left-0 top-0 p-0 m-0">

            <div style="page-break-before: always;" class="mt-2" v-for="(packet,index) in pagedata.packets" :key="index">
            <!-- <div style="page-break-before: always;" :class="{'mt-5': index != 0}" v-for="(packet,index) in pagedata.packets" :key="index"> -->
                <table class="p-0 w-full text-black" border="collapse">
                    <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                        <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                        <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                        <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                        <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="packet.mk_weight != null">PW : {{ packet.polish_weight }}</th>
                    </tr>
                    <tr class="text-center">
                        <th colspan="9" class="text-center">
                            <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                        </th>
                    </tr>
                    <!-- <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                        <th class="border m-auto border-black">SHP</th>
                        <th class="border m-auto border-black">CL</th>
                        <th class="border m-auto border-black">PRT</th>
                        <th class="border m-auto border-black">CT</th>
                        <th class="border m-auto border-black">PL</th>
                        <th class="border m-auto border-black">SYM</th>
                        <th class="border m-auto border-black">Fl</th>
                        <th class="border m-auto border-black">TN</th>
                        <th class="border m-auto border-black">STN</th>
                    </tr>
                    <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                        <th class="border m-auto border-black">{{ packet.shape }}</th>
                        <th class="border m-auto border-black">{{ packet.color }}</th>
                        <th class="border m-auto border-black">{{ packet.purity }}</th>
                        <th class="border m-auto border-black">{{ packet.cut }}</th>
                        <th class="border m-auto border-black">{{ packet.polish }}</th>
                        <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                        <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                        <th class="border m-auto border-black">{{ packet.tension }}</th>
                        <th class="border m-auto border-black">{{ packet.stone }}</th>
                    </tr>
                    <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                        <th class="border m-auto border-black" colspan="3">L</th>
                        <th class="border m-auto border-black" colspan="3">W</th>
                        <th class="border m-auto border-black" colspan="3">H</th>
                    </tr>
                    <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                        <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.length ? packet.length : 0) }}</th>
                        <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.width ? packet.width : 0) }}</th>
                        <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.height ? packet.height : 0) }}</th>
                    </tr> -->
                </table>
                <p class="p-0 mt-1 text-black w-full text-right" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">{{packet.batch_no}}</span> / <span class="text-right">FOREVER LGD</span></p>
            </div>
        </div>
    </div>
    <!-- END: Data List -->
    <!-- BEGIN: Data List -->
    <div class="intro-y box p-5">
        <div class="flex flex-wrap items-center justify-between">
            <h2 class="text-lg font-bold">Inwards Details</h2>
            <div class="grid grid-cols-12 gap-x-2 w-full">
                <div class="col-span-6 md:col-span-3 xl:col-span-2">
                    <div>
                        <!-- <label for="update-profile-form-6" class="form-label py-1">Kapan No</label> -->
                        <input id="tabulator-html-filter-value" v-model="filters.kapan_no" type="text" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="Kapan No" />
                    </div>
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-2">
                    <select class="form-select" v-model="filters.stone_id" @change="initTabulator" aria-label="Default select example">
                        <option value="0">Select Stone</option>
                        <option v-for="(stone,index) in pagedata.stones" :key="index" :value="stone.id">{{ stone.stone_name }}</option>
                    </select>
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role != 3">
                    <select class="form-select" v-model="filters.party" @change="initTabulator" aria-label="Default select example">
                        <option value="0">Select Party</option>
                        <option v-for="(party,index) in pagedata.parties" :key="party.id" :value="party.id">{{ party.first_name + ' '+ party.last_name }}({{ index + 1 }})</option>
                    </select>
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-2">
                    <select class="form-select" v-model="filters.type" @change="initTabulator" aria-label="Default select example">
                        <option value="select">Select Status</option>
                        <option value="0">Hold</option>
                        <option value="2">Processing</option>
                        <option value="1">Active</option>
                        <option value="3">Rejected</option>
                        <option value="4">Delivered</option>
                    </select>
                </div>
                <div class="col-span-2 md:col-span-2 xl:col-span-1">
                    <div class="flex">

                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                            Reset
                        </button>
                    </div>
                </div>
                <div class="col-span-2 md:col-span-2 xl:col-span-1">
                    <div class="flex">

                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()">
                            Go
                        </button>
                    </div>
                </div>
                <!-- <div class="w-48 relative text-gray-700 dark:text-gray-300">
             <select
                 class="form-select"
                 v-model="filters.purchase_type"
                 @change="initTabulator"
                 aria-label="Inward Type"
               >
                 <option value="0">Select Type</option>
                 <option value="makeable">Makeable</option>
                 <option value="rough">Rough</option>
                 <option value="video">Video</option>
               </select>
           </div> -->
                <!-- <router-link :to="{ name: 'purchase-create' }" class="btn btn-primary shadow-md ml-3">Add Inward</router-link> -->
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: Data List -->
    <div id="superlarge-modal-size-preview2" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10">
                    <!-- <div id="vertical-form"> -->
                    <div class="flex flex-col justify-between mt-5">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex items-center">
                                <h2 class="intro-y text-lg font-medium mr-5">All Packets of Lot No : <span class="ml-2 text-primary-1">{{ pagedata.lat_no }}</span></h2>
                            </div>
                        </div>
                        <div class="m-auto mt-3">
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label">Transaction Type</label>
                                <TomSelect v-model="process.transaction_type" @change="validateManagerId(process.manager_id)" :options="{
                              placeholder: 'Select Transaction Type'
                            }" class="w-60">
                                    <option value="0">Manager Transfer</option>
                                    <option value="1">Process Assign</option>
                                </TomSelect>
                                <!-- <span class="text-primary-3 mb-2" v-if="errors.manager_id">{{ errors.manager_id[0] }}</span> -->
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label">Select Manager</label>
                                <TomSelect v-model="process.manager_id" @change="validateManagerId(process.manager_id)" :options="{
                              placeholder: 'Select Manager'
                            }" class="w-60">
                                    <option value="0">Select Manager</option>
                                    <option v-for="(manager,ind) in pagedata.managers" :key="ind" :value="manager.id">{{ manager.first_name + ' ' + manager.last_name }}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="pagedata.errors.manager_id">{{ pagedata.errors.manager_id[0] }}</span>
                            </div>
                            <div class="mt-3" v-if="process.manager_id != '0' && process.transaction_type == '1'">
                                <label for="update-profile-form-6" class="form-label">Select Process</label>
                                <TomSelect v-model="process.process_id" @change="getMultiplePktEmployees(process.process_id)" :options="{
                              placeholder: 'Select Process'
                            }" class="w-60">
                                    <option value="0">Select Process</option>
                                    <option v-for="(process,ind) in pagedata.multipleprocess" :key="ind" :value="process.id">{{ process.process_name }}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="pagedata.errors.process_id">{{ pagedata.errors.process_id[0] }}</span>

                            </div>
                            <div class="flex items-center mt-3" v-if="process.process_id != '0' && process.transaction_type == '1'">
                                <div class="form-check">
                                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="singleinternal" id="singleinternal" v-model="process.eternals">
                                    <label class="form-check-label inline-block text-black text-lg" for="singleinternal">
                                        Internal
                                    </label>
                                </div>
                                <div class="form-check ml-6">
                                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="singleexternal" id="singleexternal" v-model="process.eternals">
                                    <label class="form-check-label inline-block text-black text-lg" for="singleexternal">
                                        Enternal
                                    </label>
                                </div>
                            </div>
                            <div class="mt-3" v-if="process.eternals == '0' && process.process_id != '0' && process.transaction_type == '1'">
                                <label for="update-profile-form-6" class="form-label">Select Employee</label>
                                <TomSelect v-model="process.employee_id" :options="{
                                placeholder: 'Select Employee'
                              }" class="w-60">
                                    <option value="0">Select Employee</option>
                                    <option v-for="(emp,ind) in pagedata.multipleemployees" :key="ind" :value="emp.id">{{ emp.first_name + ' ' + emp.last_name}}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="pagedata.errors.employee_id">{{ pagedata.errors.employee[0] }}</span>
                            </div>
                            <div class="mt-3" v-else-if="process.eternals == '1' && process.process_id != '0' && process.transaction_type == '1'">
                                <label for="update-profile-form-6" class="form-label">Select Party</label>
                                <TomSelect v-model="process.party_id" :options="{
                            placeholder: 'Select Party'
                          }" class="w-60">
                                    <option value="0">Select Party</option>
                                    <option v-for="(party,ind) in pagedata.multipleparties" :key="ind" :value="party.id">{{ party.firm_name }}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="pagedata.errors.party_id">{{ pagedata.errors.party_id[0] }}</span>
                            </div>
                        </div>
                        <button type="button" @click="AssignProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-3">Submit
                            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                        <!-- END: Data List -->
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: Single Process Assign Modal -->
    <div id="single-process-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10">
                    <div class="flex flex-col justify-between mt-5">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex items-center">
                                <h2 class="intro-y text-lg font-medium mr-5">Packet No : <span class="ml-2 text-primary-1">{{ singleProcess.number }}</span></h2>
                            </div>
                        </div>
                        <div class="m-auto mt-3">
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label">Transaction Type</label>
                                <TomSelect v-model="singleProcess.transaction_type" @change="singlevalidateManagerId(singleProcess.manager_id)" :options="{
                          placeholder: 'Select Transaction Type'
                        }" class="w-60">
                                    <option value="0">Manager Transfer</option>
                                    <option value="1">Process Assign</option>
                                </TomSelect>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label">Select Manager</label>
                                <TomSelect v-model="singleProcess.manager_id" @change="singlevalidateManagerId(singleProcess.manager_id)" :options="{
                          placeholder: 'Select Manager'
                        }" class="w-60">
                                    <option value="0">Select Manager</option>
                                    <option v-for="(manager,ind) in pagedata.managers" :key="ind" :value="manager.id">{{ manager.first_name + ' ' + manager.last_name }}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="errors.manager_id">{{ errors.manager_id[0] }}</span>
                            </div>
                            <div class="mt-3" v-if="singleProcess.manager_id != '0' && singleProcess.transaction_type == '1'">
                                <label for="update-profile-form-6" class="form-label">Select Process</label>
                                <TomSelect v-model="singleProcess.process_id" @change="getSinglePktEmployees(singleProcess.process_id)" :options="{
                          placeholder: 'Select Process'
                        }" class="w-60">
                                    <option value="0">Select Process</option>
                                    <option v-for="(process,ind) in pagedata.singleprocess" :key="ind" :value="process.id">{{ process.process_name }}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="errors.process_id">{{ errors.process_id[0] }}</span>

                            </div>
                            <div class="flex items-center mt-3" v-if="singleProcess.process_id != '0' && singleProcess.transaction_type == '1'">
                                <div class="form-check">
                                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="singleinternal" id="singleinternal" v-model="singleProcess.eternals">
                                    <label class="form-check-label inline-block text-black text-lg" for="singleinternal">
                                        Internal
                                    </label>
                                </div>
                                <div class="form-check ml-6">
                                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="singleexternal" id="singleexternal" v-model="singleProcess.eternals">
                                    <label class="form-check-label inline-block text-black text-lg" for="singleexternal">
                                        Enternal
                                    </label>
                                </div>
                            </div>
                            <div class="mt-3" v-if="singleProcess.eternals == '0' && singleProcess.process_id != '0' && singleProcess.transaction_type == '1'">
                                <label for="update-profile-form-6" class="form-label">Select Employee</label>
                                <TomSelect v-model="singleProcess.employee_id" :options="{
                            placeholder: 'Select Employee'
                          }" class="w-60">
                                    <option value="0">Select Employee</option>
                                    <option v-for="(emp,ind) in pagedata.singleemployees" :key="ind" :value="emp.id">{{ emp.first_name + ' ' + emp.last_name}}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="errors.employee_id">{{ errors.employee_id[0] }}</span>
                            </div>
                            <div class="mt-3" v-else-if="singleProcess.eternals == '1' && singleProcess.process_id != '0' && singleProcess.transaction_type == '1'">
                                <label for="update-profile-form-6" class="form-label">Select Party</label>
                                <TomSelect v-model="singleProcess.party_id" :options="{
                         placeholder: 'Select Party'
                       }" class="w-60">
                                    <option value="0">Select Party</option>
                                    <option v-for="(party,ind) in pagedata.singleparties" :key="ind" :value="party.id">{{ party.firm_name }}</option>
                                </TomSelect>
                                <span class="text-primary-3 mb-2" v-if="errors.party_id">{{ errors.party_id[0] }}</span>
                            </div>
                        </div>
                        <button type="button" @click="AssignSingleProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END:Single Process Assign Modal -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="p-5 text-center">
                        <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                        <div class="text-3xl mt-5">Are you sure?</div>
                        <div class="text-gray-600 mt-2">
                            Do you really want to Cancel these Inward? <br />This process
                            cannot be undone.
                        </div>
                    </div>
                    <div class="px-5 pb-8 text-center">
                        <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">
                            Cancel
                        </button>
                        <button type="button" @click="DeleteInward" class="btn btn-danger w-24">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal-single" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="p-5 text-center">
                        <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                        <div class="text-3xl mt-5">Are you sure?</div>
                        <div class="text-gray-600 mt-2">
                            Do you really want to Cancel these Inward? <br />This process
                            cannot be undone.
                        </div>
                    </div>
                    <div class="px-5 pb-8 text-center">
                        <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">
                            Cancel
                        </button>
                        <button type="button" @click="DeletePacket" class="btn btn-danger w-24">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- END: Data List -->
    <div id="add-packet-part-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10">
                    <!-- <div id="vertical-form"> -->
                    <div class="flex flex-col justify-between mt-5">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex items-center">
                                <h2 class="intro-y text-lg font-medium mr-5">Update Params</h2>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="intro-y col-span-12">
                                <!-- BEGIN: Input -->
                                <div class="">
                                    <div id="input" class="py-5">
                                        <div class="preview">
                                            <div>
                                                <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div class="">
                                                            <div id="input-group-4">Packet No</div>

                                                            <input id="pos-form-1" v-model="pagedata.comment_form.packet_no" name="kapan" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                      <div class="">
                                                            <div id="input-group-4">Stock No</div>

                                                            <input id="pos-form-1" v-model="pagedata.comment_form.vepari_no" name="stock" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Available Weight(Ct)</div>
                                                                <input type="text" class="form-control" name="piece" disabled v-model="pagedata.comment_form.weight" @input="$h.preventInvalidInput" aria-describedby="input-group-3" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                      <div>

                                                        <div class="">
                                                            <div id="input-group-3">New Weight(Ct)</div>
                                                            <input type="text" class="form-control" name="new_weight" v-model="pagedata.comment_form.new_weight" @input="$h.preventInvalidInput" aria-describedby="input-group-3" />
                                                        </div>

                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Length</div>
                                                                <input type="text" class="form-control" name="length"  v-model="pagedata.comment_form.length" aria-describedby="input-group-4" @input="$h.preventInvalidInput" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Width</div>
                                                            <input type="text" class="form-control" name="width" v-model="pagedata.comment_form.width" aria-describedby="input-group-4" @input="$h.preventInvalidInput" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Height</div>
                                                                <input type="text" class="form-control" name="length"  v-model="pagedata.comment_form.height" aria-describedby="input-group-4" @input="$h.preventInvalidInput" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                      <div class="">
                                                            <div id="input-group-4">Comment</div>
                                                            <input type="text" class="form-control" name="new comment" v-model="pagedata.comment_form.comment" aria-describedby="input-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="mt-3" v-if="reject_form.purchase_type == 2">
                                                  <div id="input-group-3" class="">Rejection Type</div>
                                                  <select class="form-select w-full" v-model="reject_form.rejection_type" disabled aria-label="Default select example">
                                                      <option value="1">Direct Rejection</option>
                                                      <option value="2" v-if="reject_form.purchase_type == 2">Net Rejection</option>
                                                  </select>
                                            </div> -->
                                            <div class="mt-3">
                                                <!-- <label class="form-label">Stone && Est Value</label> -->
                                                <div class="w-full">
                                                    <!-- <div>

                                                        <div class="">

                                                        </div>
                                                    </div> -->
                                                    <div class="flex items-center justify-end">

                                                      <div>
                                                          <div class="mt-2 sm:mt-0">
                                                              <div class="flex justify-end items-center mt-5">
                                                                  <button type="button" :disabled="pagedata.comment_form.loading == true" class="btn btn-secondary w-24 m-auto mr-3 btn-sm" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                  <button type="button" @click="AddComment" :disabled="pagedata.comment_form.loading == true" class="btn whitespace-nowrap btn-primary btn-sm w-28 m-auto"> Update
                                                                      <LogInIcon v-if="pagedata.comment_form.loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                      <LoadingIcon v-if="pagedata.comment_form.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END: Input -->
                            </div>
                        </div>
                        <!-- END: Data List -->
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: Single Process Assign Modal -->
</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config'
import router from '@/router'
import axios from 'axios'
// import VueBarcode from 'vue-barcode'
import Toastify from 'toastify-js'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import {
    computed,
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref,
    toRef,
    watch
} from 'vue'
import {
    useStore
} from '@/store'

export default defineComponent({
    setup() {
      const store = useStore()
        const user_details = computed(() => store.state.user.currentUser)
        const pagedata = reactive({
            access: false,
            errors: [],
            lat_no: '',
            serach: '',
            type: '',
            cancelId: '',
            party: '',
            partyproc: [],
            purchases: [],
            packets: [],
            managers: [],
            employees: [],
            multipleprocess: [],
            multipleemployees: [],
            multipleparties: [],
            singleprocess: [],
            singleemployees: [],
            singleparties: [],
            processes: [],
            externals: [],
            mulerrors: {
                manager: [],
                process: [],
                employee: []
            },
            submit: false,
            parties: [],
            stones: [],
            message: '',
            error: '',

            comment_form: {
              packet_no: '',
              vepari_no: '',
              packet_id: '',
              comment: '',
              length: '',
              height: '',
              width: '',
              weight: '',
              new_weight: '',
              loading: false
            }
        })
        const errors = toRef(pagedata, 'errors')
        const pagelinks = toRef(pagedata, 'pagelinks')
        const process = reactive({
            id: '',
            eternals: '0',
            number: '',
            process_id: '0',
            employee_id: '0',
            manager_id: '0',
            party_id: '0',
            transaction_type: '0',
            type: ''
        })
        const singleProcess = reactive({
            id: '',
            eternals: '0',
            number: '',
            process_id: '0',
            employee_id: '0',
            manager_id: '0',
            party_id: '0',
            transaction_type: '0',
            type: ''
        })
        const tableRef = ref()
        const tabulator = ref()
        const swal = inject('$swal')
        onMounted(() => {
            initTabulator()
            getParties()
            getStones()
        })
        const filters = reactive({
            purchase_type: '0',
            type: 'select',
            party: '0',
            stone_id: '0',
            kapan_no: ''
        })
        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/purchase/get_purchase_details',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: {
                    purchase_type: filters.purchase_type == '0' ? '' : window.btoa(filters.purchase_type),
                    kapan_no: filters.kapan_no == '' ? '' : window.btoa(filters.kapan_no),
                    party: filters.party == '0' ? '' : window.btoa(filters.party),
                    stone_id: filters.stone_id == '0' ? '' : window.btoa(filters.stone_id),
                    type: filters.type == 'select' ? '' : window.btoa(filters.type)
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                headerSort: false,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: 'No matching records found',
                columns: [{
                        formatter: 'responsiveCollapse',
                        width: 40,
                        minWidth: 30,
                        hozAlign: 'center',
                        resizable: false,
                        headerSort: false
                    },

                    // For HTML table
                    {
                        title: 'Lot No',
                        responsive: 0,
                        minWidth: 70,
                        field: 'lot_no',
                        //  vertAlign: 'middle',
                        //  hozAlign: 'center',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().lot_no
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },
                    {
                        title: 'Kapan',
                        field: 'voucher_no',
                        responsive: 0,
                        minWidth: 100,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().voucher_no != null ? cell.getData().voucher_no : '-'
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },
                    {
                        title: 'Type',
                        responsive: 0,
                        minWidth: 70,
                        field: 'type',
                        vertAlign: 'middle',
                        hozAlign: 'center',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().type
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },
                    {
                        title: 'Party',
                        responsive: 0,
                        minWidth: 100,
                        field: 'firm_name',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().firm_name
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },
                    {
                        title: 'Duration',
                        responsive: 0,
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        field: 'due',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().due
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },
                    {
                        title: 'Pices',
                        field: 'pieces',
                        minWidth: 50,
                        //  hozAlign: 'center',
                        //  vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().pieces
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },
                    {
                        title: 'Total Wt',
                        responsive: 0,
                        minWidth: 100,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        field: 'total_weight',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().total_weight
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },

                    {
                        title: 'Stone',
                        field: 'stone_name',
                        minWidth: 50,
                        //  hozAlign: 'center',
                        //  vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().stone_name
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                        }
                    },
                    {
                        title: 'Est Majuri',
                        field: 'est_value',
                        minWidth: 50,
                        //  hozAlign: 'center',
                        //  vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().est_value ? cell.getData().est_value : '-'
                 }</div>
               </div>`)
                            return a[0]
                        }
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        responsive: 0,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                          // if(user_details.value.user_role == 3) {

                          // } else {

                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().status == 2 ? '<p class="text-primary-1">In Process</p>': cell.getData().status == 1 ? '<p class="text-primary-8">Active</p>' : cell.getData().status == 0 ? '<p class="text-primary-6">Hold</p>' : cell.getData().status == 3 ? '<p class="text-primary-3">Cancelled</p>' : '<p class="text-primary-4">Delevierd</p>'
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            })
                            return a[0]
                          // }
                        }
                    },
                    {
                        hozAlign: 'center',
                        field: 'status',
                        width: 10,
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                          if(user_details.value.user_role == 3) {

                          } else {

                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   (cell.getData().status == 1 || cell.getData().status == 0) && user_details.value.account_id == cell.getData().manager_id ? '<button title="Edit" class="flex items-center mr-3"> <i data-feather="edit" class="w-4 h-4"></i> </button>' : cell.getData().status == 0  && user_details.value.account_id != cell.getData().manager_id ? '<button title="Edit" class="flex items-center mr-3"> <i data-feather="edit" class="w-4 h-4"></i> </button>' : ''
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                router.push({
                                    path: '/purchase/update/' + window.btoa(cell.getData().id)
                                })
                            })
                            return a[0]
                          }
                        }
                    },
                    {
                        hozAlign: 'center',
                        field: 'status',
                        width: 10,
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                          if(user_details.value.user_role == 3) {

                          } else {

                            const a = cash(`<div class="w-full m-0">
                <div class="whitespace-nowrap">${
                  (cell.getData().status == 1 || cell.getData().status == 0) && user_details.value.account_id == cell.getData().manager_id ? '<button class="flex items-center text-theme-6 mr-3"> <i data-feather="trash" class="w-4 h-4"></i> </button>' : cell.getData().status == 0 && user_details.value.account_id != cell.getData().manager_id ? '<button class="flex items-center text-theme-6 mr-3"> <i data-feather="trash" class="w-4 h-4"></i> </button>' : ''
                }</div>
               </div>`)
                            cash(a).on('click', function () {
                                pagedata.cancelId = cell.getData().id
                                cash('#delete-confirmation-modal').modal('show')
                            })
                            return a[0]
                          }
                        }
                    },
                    {
                        hozAlign: 'center',
                        field: 'status',
                        width: 10,
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                          if(user_details.value.user_role == 3) {

                          } else {

                            const a = cash(`<div class="w-full m-0">
                 <div class="whitespace-nowrap">${
                   cell.getData().status == 0 && user_details.value.account_id == cell.getData().manager_id ? '<button class="flex items-center text-theme-1"> A </button>' : cell.getData().status == 1 && user_details.value.account_id == cell.getData().manager_id ? '<button class="flex items-center text-theme-9"><i data-feather="chevrons-right" class="w-4 h-4"></i></button>' : ''
                 }</div>
               </div>`)
                            cash(a).on('click', function () {
                                if (cell.getData().status == 0 && user_details.value.account_id == cell.getData().manager_id) {
                                    AcceptAll(cell.getData().id)
                                } else if (cell.getData().status == 1 && user_details.value.account_id == cell.getData().manager_id) {
                                    cash('#superlarge-modal-size-preview2').modal('show')
                                    ProcessAssign(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                                }
                            })
                            return a[0]
                          }
                        }
                    }
                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        function Packet(id) {
            pagedata.packets = []
            const params = {
                id: window.btoa(id)
            }
            axios({
                url: 'api/purchase/get_purchase_packets',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.packets = res.data.items
            });
        }

        function DeleteInward() {
            const params = {
                id: window.btoa(pagedata.cancelId)
            }
            axios({
                url: 'api/purchase/reject_purchase',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then(() => {
                initTabulator()
                if (pagedata.packets.length > 0) {
                    Packet(pagedata.cancelId)
                }
            })
            cash('#delete-confirmation-modal').modal('hide')
        }

        function ProcessAssign(id, lot, type) {
            Packet(id)
            process.type = type
            pagedata.lat_no = lot
            process.id = id
            getAllManager()
        }

        function SinglePacketAssign(id, no, type) {
            singleProcess.number = no
            singleProcess.id = id
            singleProcess.type = type
            getAllManager()
        }

        function getAllManager() {
            axios({
                url: 'api/master/dp_managers',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.managers = res.data.items

                //  var i = pagedata.managers.length;
                //   while (i--) {
                //       if (pagedata.managers[i]['id'] == user_details.value.account_id) {
                //           pagedata.managers.splice(i, 1);
                //       }
                //   }
                process.manager_id = '0'
            })
        }

        function getSinglePktEmployees(id) {
            const params = {
                manager_id: window.btoa(singleProcess.manager_id),
                process_id: window.btoa(id),
                process_type: window.btoa(singleProcess.type),
            }
            if (id != '0' && singleProcess.manager_id != '0') {

                axios({
                    url: 'api/master/get_employees_by_manager_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    pagedata.singleemployees = res.data.items
                    singleProcess.employee_id = '0'
                })
                axios({
                    url: 'api/master/get_jober_details_by_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    pagedata.singleparties = res.data.items
                    singleProcess.party_id = '0'
                })
            } else {
                pagedata.singleemployees = []
                pagedata.singleparties = []
                singleProcess.party_id = '0'
                singleProcess.employee_id = '0'
            }
        }

        function getMultiplePktEmployees(id) {
            const params = {
                manager_id: window.btoa(process.manager_id),
                process_id: window.btoa(id),
                process_type: window.btoa(process.type),
            }
            if (id != '0' && process.manager_id != '0') {

                axios({
                    url: 'api/master/get_employees_by_manager_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    pagedata.multipleemployees = res.data.items
                    process.employee_id = '0'
                })
                axios({
                    url: 'api/master/get_jober_details_by_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    pagedata.multipleparties = res.data.items
                    process.party_id = '0'
                })
            } else {
                pagedata.multipleemployees = []
                pagedata.multipleparties = []
                process.party_id = '0'
                process.employee_id = '0'
            }
        }

        function getParties() {
            axios({
                url: 'api/master/dp_parties',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.parties = res.data.items
            })
        }

        function getStones() {
            axios({
                url: 'api/master/dp_stone',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.stones = res.data.items
            })
        }

        function getSingleProcessData(id) {
            if (id != 0) {
                const params = {
                    user_id: id,
                    process_type: window.btoa(singleProcess.type)
                }
                if (id != '0') {
                    axios({
                        url: 'api/master/manager_processes',
                        method: 'post',
                        baseURL: BASE_URL,
                        data: params,
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        },
                        config: {
                            headers: {
                                'Accept': 'application/json'
                            }
                        }
                    }).then((res) => {
                        pagedata.singleprocess = res.data.items
                        singleProcess.process_id = '0'
                    })
                } else {
                    pagedata.singleprocess = []
                    singleProcess.process_id = '0'
                }
            }
        }

        function getMultipleProcessData(id) {
            if (id != 0) {
                const params = {
                    user_id: id,
                    process_type: window.btoa(process.type)
                }
                if (id != '0') {
                    axios({
                        url: 'api/master/manager_processes',
                        method: 'post',
                        baseURL: BASE_URL,
                        data: params,
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        },
                        config: {
                            headers: {
                                'Accept': 'application/json'
                            }
                        }
                    }).then((res) => {
                        pagedata.multipleprocess = res.data.items
                        process.process_id = '0'
                    })
                } else {
                    pagedata.multipleprocess = []
                    process.process_id = '0'
                }
            }
        }

        function AssignProcess() {
            const params = {
                id: window.btoa(process.id),
                externals: window.btoa(process.eternals),
                number: window.btoa(process.number),
                process_id: process.process_id == '0' ? '' : window.btoa(process.process_id),
                employee_id: process.employee_id == '0' ? '' : window.btoa(process.employee_id),
                manager_id: process.manager_id == '0' ? '' : window.btoa(process.manager_id),
                party_id: process.party_id == '0' ? '' : window.btoa(process.party_id),
                transaction_type: window.btoa(process.transaction_type),
            }
            pagedata.submit = true
            let validate = true
            if (validate == true) {
                axios({
                    url: 'api/process/admin_multiple_issue',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    pagedata.submit = false
                    initTabulator()
                    if (pagedata.packets.length > 0) {
                        Packet(res.data.purchase)
                    }
                    setTimeout(() => {
                        pagedata.multipleemployees = []
                        pagedata.multipleparties = []
                        pagedata.multipleprocess = []
                        process.party_id = '0'
                        process.process_id = '0'
                        process.manager_id = '0'
                        process.employee_id = '0'
                        process.id = 0
                        process.eternals = '0'
                    }, 100);
                    pagedata.message = res.data.success
                    setTimeout(() => {
                        basicNonStickyNotificationPacketSuccess()
                    }, 30);
                    setTimeout(() => {
                        pagedata.message = ''
                    }, 3000);
                    cash('#superlarge-modal-size-preview2').modal('hide')
                }).catch((err) => {
                    pagedata.submit = false
                    console.warn(err.response.data.error)
                    if (err.response.data.error) {
                        swal("Warning!", err.response.data.error, "warning")
                    } else {
                        pagedata.errors = err.response.data.errors
                        setTimeout(() => {
                            pagedata.errors = []
                        }, 3000);
                    }
                })
            } else {
                pagedata.submit = false
            }
        }
        function AddStoneDetails(packet) {
          pagedata.comment_form.packet_no = packet.number
          pagedata.comment_form.vepari_no = packet.vepari_no
          pagedata.comment_form.packet_id = packet.id
          pagedata.comment_form.weight = packet.cr_weight
          pagedata.comment_form.new_weight = ""
          pagedata.comment_form.comment = (packet.comment ? packet.comment : '')
          pagedata.comment_form.height = (packet.height ? packet.height : '')
          pagedata.comment_form.width = (packet.width ? packet.width : '')
          pagedata.comment_form.length = (packet.length ? packet.length : '')
        }
        function AddComment() {
            const params = {
                id: window.btoa(pagedata.comment_form.packet_id),
                comment: window.btoa(pagedata.comment_form.comment),
                height: window.btoa(pagedata.comment_form.height),
                width: window.btoa(pagedata.comment_form.width),
                length: window.btoa(pagedata.comment_form.length),
                new_weight: window.btoa(pagedata.comment_form.new_weight),
            }

            let promise_url = 'api/purchase/addComment'
            pagedata.comment_form.loading = true

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.status == 'error') {
                        swal('Warning', data.message, 'warning');
                        pagedata.comment_form.loading = false
                    } else {
                        swal('Success', data.message, 'success');

                        pagedata.comment_form.packet_no = ''
                        pagedata.comment_form.vepari_no = ''
                        pagedata.comment_form.packet_id = ''
                        pagedata.comment_form.weight = ''
                        pagedata.comment_form.new_weight = ''
                        pagedata.comment_form.height = ''
                        pagedata.comment_form.width = ''
                        pagedata.comment_form.length = ''
                        pagedata.comment_form.comment = ''
                        pagedata.comment_form.loading = false

                        cash('#add-packet-part-modal').modal('hide')
                        Packet(data.purchase)
                    }
                })
                .catch(_error => {
                  pagedata.loading = false
                    swal('Error', 'Something went wrong.', 'error');
                });
        }

        function AssignSingleProcess() {
            const params = {
                id: window.btoa(singleProcess.id),
                externals: window.btoa(singleProcess.eternals),
                number: window.btoa(singleProcess.number),
                process_id: singleProcess.process_id == '0' ? '' : window.btoa(singleProcess.process_id),
                employee_id: singleProcess.employee_id == '0' ? '' : window.btoa(singleProcess.employee_id),
                manager_id: singleProcess.manager_id == '0' ? '' : window.btoa(singleProcess.manager_id),
                party_id: singleProcess.party_id == '0' ? '' : window.btoa(singleProcess.party_id),
                transaction_type: window.btoa(singleProcess.transaction_type),
            }
            pagedata.submit = true
            axios({
                url: `api/process/admin_single_issue`,
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                pagedata.submit = false
                initTabulator()
                if (pagedata.packets.length > 0) {
                    Packet(res.data.purchase)
                }
                setTimeout(() => {
                    pagedata.employees = []
                    pagedata.processes = []
                    pagedata.singleparties = []
                    singleProcess.number = ''
                    singleProcess.manager_id = '0'
                    singleProcess.party_id = '0'
                    singleProcess.employee_id = '0'
                    singleProcess.process_id = '0'
                    singleProcess.id = 0
                    singleProcess.eternals = '0'
                }, 100);

                pagedata.message = res.data.success
                setTimeout(() => {
                    basicNonStickyNotificationPacketSuccess()
                }, 30);
                setTimeout(() => {
                    pagedata.message = ''
                }, 3000);
                // basicNonStickyNotificationPacketAssign()
                cash('#single-process-modal').modal('hide')
            }).catch((err) => {
                pagedata.submit = false
                if (err.response.data.error) {
                    swal("Warning!", err.response.data.error, "warning")
                } else {
                    pagedata.errors = err.response.data.errors
                    setTimeout(() => {
                        pagedata.errors = []
                    }, 3000);
                }
            })
        }

        function DeletePacket() {
            const params = {
                id: window.btoa(pagedata.cancelId)
            }
            axios({
                url: 'api/purchase/reject_packet',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {

                if (res.data.status == 'error') {
                    cash('#delete-confirmation-modal-single').modal('hide')
                    swal("Warning!", res.data.message, "warning");
                    return;
                } else {
                    initTabulator()
                    if (pagedata.packets.length > 0) {
                        Packet(res.data.purchase)
                    }
                    cash('#delete-confirmation-modal-single').modal('hide')

                }
            })
        }

        function validateManagerId(id) {
            if (user_details.value.account_id == process.manager_id && process.transaction_type == "0") {
                process.manager_id = '0'
                swal("Warning!", "Self Transfre is Not Allowed !", "warning");
            }
            getMultipleProcessData(id)
        }

        function singlevalidateManagerId(id) {
            if (user_details.value.account_id == singleProcess.manager_id && singleProcess.transaction_type == "0") {
                singleProcess.manager_id = '0'
                swal("Warning!", "Self Transfre is Not Allowed !", "warning");
            }
            getSingleProcessData(id)
        }
        const basicNonStickyNotificationPacketSuccess = () => {
            Toastify({
                node: cash('#basic-non-sticky-notification-packet-success')
                    .clone()
                    .removeClass('hidden')[0],
                duration: 5000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
            }).showToast()
        }

        function AddToStock(id) {
            const params = {
                id: window.btoa(id)
            }
            axios({
                url: 'api/purchase/accept_packet',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                initTabulator()
                if (pagedata.packets.length > 0) {

                    Packet(res.data.purchase)

                }
            })
        }

        function AcceptAll(id) {
            const params = {
                id: window.btoa(id)
            }
            axios({
                url: 'api/purchase/accept_purchase',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                initTabulator()
                if (pagedata.packets.length > 0) {
                    Packet(res.data.purchase)
                }
            })
        }

        const onResetFilter = () => {
            filters.purchase_type = '0'
            filters.type = 'select'
            filters.party = '0'
            filters.stone_id = '0'
            filters.kapan_no = ''
        }
        return {
            pagedata,
            filters,
            tableRef,
            process,
            errors,
            pagelinks,
            singleProcess,
            user_details,
            Packet,
            initTabulator,
            DeleteInward,
            ProcessAssign,
            SinglePacketAssign,
            AssignProcess,
            getMultipleProcessData,
            DeletePacket,
            AddToStock,
            AcceptAll,
            getSingleProcessData,
            getSinglePktEmployees,
            AssignSingleProcess,
            getMultiplePktEmployees,
            singlevalidateManagerId,
            validateManagerId,
            onResetFilter,
            AddComment,
            AddStoneDetails
        }
    }
})
</script>
