<template>
  <div>
    <div class="pos intro-y grid">
      <h2 class="text-lg font-medium mr-auto">Party Report</h2>
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 border-b text-xs whitespace-nowrap"></th>
                <th class="py-2 border-b text-xs whitespace-nowrap"></th>
                <th class="py-2 border-b border-r border-l text-xs whitespace-nowrap" colspan="2">In Inward</th>
                <th class="py-2 border-b border-r text-xs whitespace-nowrap" colspan="2">Inward</th>
                <th class="py-2 border-b border-r text-xs whitespace-nowrap" colspan="3">Stock</th>
                <th class="py-2 border-b text-xs whitespace-nowrap" colspan="4">Delivered</th>
              </tr>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Party</th>
                <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                <th class="py-2 text-xs whitespace-nowrap">Make Wt</th>
                <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                <th class="py-2 text-xs whitespace-nowrap">Make Wt</th>
                <th class="py-2 text-xs whitespace-nowrap">Pol Wt</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-gray-200 text-gray-700">
                <td class="py-2 border-b text-xs text-center"></td>
                <td class="py-2 border-b text-xs text-center"></td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_inpurchase_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_inpurchase_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_inward_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_inward_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_stock_packets - pagedata.total_inward_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_stock_weight - pagedata.total_inward_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_stock_pwt - pagedata.total_inward_pwt_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_delivered_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_delivered_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_delivered_pwt_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_delivered_current_weight).toFixed(2) }}</td>
              </tr>
              <tr v-for="(party, index) in pagedata.parties" :key="index" class="cursor-pointer">
                <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                <td class="py-2 border-b text-xs text-center">{{ party.party }}</td>
                <td class="py-2 border-b text-xs text-center">{{ party.total_inpurchase_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_inpurchase_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ party.total_inward_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_inward_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ party.total_stock_packets - party.total_inward_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_stock_weight - party.total_inward_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_stock_pwt - party.total_inward_pwt_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ party.total_delivered_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_delivered_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_delivered_pwt_weight).toFixed(2) }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_delivered_current_weight).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import cash from 'cash-dom';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      parties: [],
      total_inpurchase_packets: null,
      total_inpurchase_weight: null,
      total_inward_packets: null,
      total_inward_weight: null,
      total_inward_pwt_weight: null,
      total_stock_packets: null,
      total_stock_weight: null,
      total_stock_pwt: null,
      total_delivered_packets: null,
      total_delivered_weight: null,
      total_delivered_pwt_weight: null,
      total_delivered_current_weight: null
    })
    onMounted(() => {
      getAvailPkt()
    })
    function getAvailPkt() {
       axios({
        url: `/report/party_report`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.parties
        pagedata.total_inpurchase_packets = res.data.total[0].total_inpurchase_packets
        pagedata.total_inpurchase_weight = res.data.total[0].total_inpurchase_weight
        pagedata.total_inward_packets = res.data.total[0].total_inward_packets
        pagedata.total_inward_weight = res.data.total[0].total_inward_weight
        pagedata.total_inward_pwt_weight = res.data.total[0].total_inward_pwt_weight
        pagedata.total_stock_packets = res.data.total[0].total_stock_packets
        pagedata.total_stock_weight = res.data.total[0].total_stock_weight
        pagedata.total_stock_pwt = res.data.total[0].total_stock_pwt
        pagedata.total_delivered_packets = res.data.total[0].total_delivered_packets
        pagedata.total_delivered_weight = res.data.total[0].total_delivered_weight
        pagedata.total_delivered_pwt_weight = res.data.total[0].total_delivered_pwt_weight
        pagedata.total_delivered_current_weight = res.data.total[0].total_delivered_current_weight
      })
    }
    return {
      pagedata
    }
  }
})
</script>
