<template>
  <div>
    <h2 class="text-lg font-bold py-3">Urgent Packets</h2>
    <div id="basic-non-sticky-notification-layout-pkts" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="flex items-center justify-around" v-if="pagedata.error">
          <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.error }}</div>
        </div>
        <div class="flex items-center justify-around" v-if="pagedata.success">
          <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.success }}</div>
        </div>
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <div class="w-full sm:w-auto flexsm:mt-0">
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <TomSelect
                v-model="filter.party_id"
                @change="changeParty"
                :options="{
                  placeholder: 'Select Parties'
                }"
                class="w-full"
              >
                <option value="0">Select Party</option>
                <option v-for="dept in pagedata.parties" :key="dept.id" :value="dept.id">{{ dept.firm_name ? dept.firm_name : dept.first_name + ' ' + dept.last_name }}</option>
              </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <TomSelect
                v-model="filter.voucher_no"
                @change="changevoucherNo"
                :options="{
                  placeholder: 'Select Voucher'
                }"
                class="w-full"
              >
                <option value="0">Select Voucher No</option>
                <option v-for="dept in pagedata.vouchers" :key="dept" :value="dept.voucher_no">{{ dept.voucher_no }}</option>
              </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <TomSelect
                v-model="filter.vepari_no"
                @change="initTabulator"
                :options="{
                  placeholder: 'Select Vepari No'
                }"
                class="w-full"
              >
                <option value="0">Select Vepari No</option>
                <option v-for="dept in pagedata.vepari_no" :key="dept" :value="dept.vepari_no">{{ dept.vepari_no }}</option>
              </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <TomSelect
                v-model="filter.packet_no"
                @change="initTabulator"
                :options="{
                  placeholder: 'Select Packet No'
                }"
                class="w-full"
              >
                <option value="0">Select Packet No</option>
                <option v-for="dept in pagedata.packets" :key="dept" :value="dept.number">{{ dept.number }}</option>
              </TomSelect>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import { defineComponent, inject, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const swal = inject('$swal')
    const pagedata = reactive({
      parties: [],
      packets: [],
      vouchers: [],
      vepari_no: [],
    })
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      party_id: '0',
      voucher_no: '0',
      vepari_no: '0',
      packet_no: '0',
    })
    onMounted(() => {
      initTabulator()
      getParties()
      getVoucherDetails()
      getVepariDetails()
      getPacketDetails()
    })
    function getParties() {
      axios({
        url: 'api/master/dp_parties',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.items
      })
    }
    function getVoucherDetails() {
      let params = {
        party_id : filter.party_id == '0' ?  '' : window.btoa(filter.party_id)
      }
      axios({
        url: 'api/urgent/get_voucher_list',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.vouchers = res.data.items
      })
    }
    function getVepariDetails() {
      let params = {
        party_id : filter.party_id == '0' ?  '' : window.btoa(filter.party_id),
        voucher_no : filter.voucher_no == '0' ?  '' : window.btoa(filter.voucher_no)
      }
      axios({
        url: 'api/urgent/get_vepari_no_list',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.vepari_no = res.data.items
      })
    }
    function getPacketDetails() {
      let params = {
        party_id : filter.party_id == '0' ?  '' : window.btoa(filter.party_id),
        voucher_no : filter.voucher_no == '0' ?  '' : window.btoa(filter.voucher_no)
      }
      axios({
        url: 'api/urgent/get_packet_no_list',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.items
      })
    }
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/urgent/get_packet_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams : {
          party_id : filter.party_id == '0' ? '' : window.btoa(filter.party_id),
          voucher_no : filter.voucher_no == '0' ? '' : window.btoa(filter.voucher_no),
          vepari_no : filter.vepari_no == '0' ? '' : window.btoa(filter.vepari_no),
          packet_no : filter.packet_no == '0' ? '' : window.btoa(filter.packet_no),
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        headerSort: false,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        dataTree:true,
        dataTreeStartExpanded:true,
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Lot No /<br>Packet No',
            responsive: 0,

            field: 'lot',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().lot_no ? cell.getData().lot_no : (cell.getData().number ? cell.getData().number : '-')
                }</div>
              </div>`
            }
          },
          {
            title: 'Voucher No /<br>Vepari No',
            responsive: 0,

            field: 'voucher',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().voucher_no ? cell.getData().voucher_no : (cell.getData().vepari_no ? cell.getData().vepari_no : '-')
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            responsive: 0,
            field: 'party',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().firm_name ? cell.getData().firm_name : cell.getData().first_name + ' ' + cell.getData().last_name
                }</div>
              </div>`
            }
          },
          {
            title: 'Type',
            responsive: 0,
            field: 'type',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
            }
          },
          {
            title: 'Action',
            field: 'urgent',
            minWidth: 100,
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                ${
                  cell.getData().urgent == null ? '<button class="btn btn-primary p-1 px-2 mr-1"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Add </button>' : '<a class="flex items-center text-theme-6"> <i data-feather="trash-2" class="w-4 h-4 mr-1"></i> Cancel</a>'
                }
              </div>`)
              cash(a).on('click', function() {
                if(cell.getData().urgent == null && cell.getData().lot_no) {
                  AddInwardUrgent(cell.getData().id)
                } else if(cell.getData().urgent != null && cell.getData().lot_no) {
                  CancelInwardUrgent(cell.getData().id)
                } else if(cell.getData().urgent == null && cell.getData().number) {
                  AddPacketUrgent(cell.getData().id)
                } else if(cell.getData().urgent != null && cell.getData().number) {
                  CancelPacketUrgent(cell.getData().id)
                }
              })

              return a[0]
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function AddPacketUrgent(id) {
      swal({
        title: "Are you sure?",
        text: "You are sure, You want To Transfer Packet To Urgent!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){
          axios({
              url: 'api/urgent/add_urgent_packet',
              method: 'post',
              baseURL: BASE_URL,
              data: { id: window.btoa(id)},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              if(res.data.success == "NO") {
                swal("Warning!", res.data.message, "warning");
              } else {
                swal("Success!", res.data.message, "success");
                initTabulator()
              }
            })
        }
      });
    }
    function CancelPacketUrgent(id) {
      swal({
        title: "Are you sure?",
        text: "You are sure, You want To Cancel Packet To Urgent!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){
          axios({
              url: 'api/urgent/remove_urgent_packet',
              method: 'post',
              baseURL: BASE_URL,
              data: { id: window.btoa(id)},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              if(res.data.success == "NO") {
                swal("Warning!", res.data.message, "warning");
              } else {
                swal("Success!", res.data.message, "success");
                initTabulator()
              }
            })
        }
      });
    }
    function AddInwardUrgent(id) {
      swal({
        title: "Are you sure?",
        text: "You are sure, You want To Transfer Packets To Urgent!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){
          axios({
              url: 'api/urgent/add_urgent_purchase',
              method: 'post',
              baseURL: BASE_URL,
              data: { id: window.btoa(id)},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              if(res.data.success == "NO") {
                swal("Warning!", res.data.message, "warning");
              } else {
                swal("Success!", res.data.message, "success");
                initTabulator()
              }
            })
        }
      });
    }
    function CancelInwardUrgent(id) {
      swal({
        title: "Are you sure?",
        text: "You are sure, You want To Cancel Packets To Urgent!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){
          axios({
              url: 'api/urgent/remove_urgent_purchase',
              method: 'post',
              baseURL: BASE_URL,
              data: { id: window.btoa(id)},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              if(res.data.success == "NO") {
                swal("Warning!", res.data.message, "warning");
              } else {
                swal("Success!", res.data.message, "success");
                initTabulator()
              }
            })
        }
      });
    }
    function changeParty() {
      if(filter.voucher_no == '0' && filter.vepari_no == '0' && filter.packet_no == '0') {
        initTabulator()
        getVoucherDetails()
        getVepariDetails()
        getPacketDetails()
      } else {
        filter.voucher_no = '0'
        if(filter.vepari_no == '0' && filter.packet_no == '0') {
          initTabulator()
        }
      }
    }
    function changevoucherNo() {
      if(filter.vepari_no == '0' && filter.packet_no == '0') {
        initTabulator()
      } else {
        filter.vepari_no = '0'
        filter.packet_no = '0'
      }
      getVepariDetails()
      getPacketDetails()
    }
    return {
      pagedata,
      filter,
      tableRef,
      initTabulator,
      changeParty,
      changevoucherNo
    }
  }
})
</script>
