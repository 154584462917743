<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-5">
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/diamond.gif" class="w-14 h-14 mb-2 text-theme-9" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.total_packets }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Total Packets</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.total_packets_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/processing.gif" class="w-14 h-14 mb-2 text-theme-10" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.processing }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Process</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.processing_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/shoppingcart.gif" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.in_purchase }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Inward</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.in_purchase_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/process.gif" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.outward }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Delivery</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.outward_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-6 mt-8">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Packets Growth</h2>
          </div>
          <div class="intro-y box p-5 mt-12 sm:mt-5">
            <div class="flex flex-col xl:flex-row xl:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-medium"
                  >
                    {{ pagedata.currerrent_month_packets }} Packets
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    This Month
                  </div>
                </div>
                <div
                  class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"
                ></div>
                <div>
                  <div
                    class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium"
                  >
                    {{ pagedata.prev_month_packets }} Packets
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    Last Month
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ReportLineChart :height="169" class="mt-6" :grawth="pagedata.party_growth" :last_grawth="pagedata.last_year_party_growth" />
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Seller -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Weekly Top Seller</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <ReportPieChart :height="300" class="mt-3" />
            <div class="mt-8">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">17 - 30 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">62%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">31 - 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">33%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">>= 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">10%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Weekly Top Seller -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Sales Report</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <ReportDonutChart :height="300" class="mt-3" />
            <div class="mt-8">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">17 - 30 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">62%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">31 - 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">33%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">>= 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">10%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-3">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              Live Processes
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table-report w-full">
              <thead>
                <tr>
                  <th class="text-center whitespace-nowrap">#</th>
                  <th class="text-center whitespace-nowrap">Vep No</th>
                  <th class="text-center whitespace-nowrap">Number</th>
                  <th class="text-center whitespace-nowrap">Weight</th>
                  <th class="text-center whitespace-nowrap">Pwt</th>
                  <th class="text-center whitespace-nowrap">C_Wt</th>
                  <th class="text-center whitespace-nowrap">C_Process</th>
                  <th class="text-center whitespace-nowrap">C_Shp</th>
                  <th class="text-center whitespace-nowrap">C_Clr</th>
                  <th class="text-center whitespace-nowrap">C_Prt</th>
                  <th class="text-center whitespace-nowrap">C_Cut</th>
                  <th class="text-center whitespace-nowrap">C_Pol</th>
                  <th class="text-center whitespace-nowrap">C_Sym</th>
                  <th class="text-center whitespace-nowrap">C_FL</th>
                  <th class="text-center whitespace-nowrap">C_TN</th>
                  <th class="text-center whitespace-nowrap">C_Stn</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(packet, index) in pagedata.processing"
                  :key="index"
                  class="intro-x"
                >
                  <td class="py-2 text-center whitespace-nowrap">{{ index + 1 }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.vepari_no }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.number }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.weight }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.admin_pwt > 0 ? packet.admin_pwt : packet.pwt }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.issue_weight }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.process }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.shape }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.color }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.purity }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.cut }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.polish }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.symmetry }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.fluerocent }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.tension }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.stone }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- BEGIN: Pagination -->
          <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
          >
            <ul class="pagination">
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="PartyDashBoardurl(pagedata.first_page_url)">
                  <ChevronsLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="PartyDashBoardurl(pagedata.prev_page_url)">
                  <ChevronLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li v-for="(pagelink,index) in pagelinks" :key="index">
                <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='PartyDashBoardurl(pagelink.url)'>{{ pagelink.label }}</button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="PartyDashBoardurl(pagedata.next_page_url)">
                  <ChevronRightIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="PartyDashBoardurl(pagedata.last_page_url)">
                  <ChevronsRightIcon class="w-4 h-4" />
                </button>
              </li>
            </ul>
            <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagedata.size" @change="PartyDashBoard">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
          <!-- END: Pagination -->
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
    <div class="col-span-12">
      <div class="pos intro-y grid">
        <h2 class="text-lg font-medium mr-auto">Party Stock</h2>
      </div>
      <!-- BEgin: Process Report Start-->
      <div class="intro-y box p-5 mt-5">
        <div class="overflow-x-auto scrollbar-hidden">
          <div class="intro-y col-span-12 overflow-auto">
            <table class="mt-2 w-full border-2" v-if="pagedata.parties.length > 0">
              <thead>
                <tr class="bg-theme-1 text-white">
                  <th class="py-2 border-b text-xs whitespace-nowrap"></th>
                  <th class="py-2 border-b text-xs whitespace-nowrap"></th>
                  <th class="py-2 border-b border-r border-l text-xs whitespace-nowrap" colspan="2">In Inward</th>
                  <th class="py-2 border-b border-r text-xs whitespace-nowrap" colspan="2">Inward</th>
                  <th class="py-2 border-b border-r text-xs whitespace-nowrap" colspan="3">Stock</th>
                  <th class="py-2 border-b text-xs whitespace-nowrap" colspan="4">Delivered</th>
                </tr>
                <tr class="bg-theme-1 text-white">
                  <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                  <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                  <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                  <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                  <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                  <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                  <th class="py-2 text-xs whitespace-nowrap">Make Wt</th>
                  <th class="py-2 text-xs border-l whitespace-nowrap">Packets</th>
                  <th class="py-2 text-xs whitespace-nowrap">Rough Wt</th>
                  <th class="py-2 text-xs whitespace-nowrap">Make Wt</th>
                  <th class="py-2 text-xs whitespace-nowrap">Pol Wt</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(party, index) in pagedata.parties" :key="index" class="cursor-pointer">
                  <td class="py-2 border-b text-xs text-center">{{ party.total_inpurchase_packets }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_inpurchase_weight).toFixed(2) }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ party.total_inward_packets }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_inward_weight).toFixed(2) }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ party.total_stock_packets - party.total_inward_packets }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_stock_weight - party.total_inward_weight).toFixed(2) }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_stock_pwt - party.total_inward_pwt_weight).toFixed(2) }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ party.total_delivered_packets }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_delivered_weight).toFixed(2) }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_delivered_pwt_weight).toFixed(2) }}</td>
                  <td class="py-2 border-b text-xs text-center">{{ parseFloat(party.total_delivered_current_weight).toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="intro-y box py-4 text-center" v-else>
                    Party has not Packets Yet !
                </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12">
      <div class="2xl:border-l border-theme-5 -mb-10 pb-10">
        <div class="2xl:pl-6 grid grid-cols-12 gap-6">
          <!-- BEGIN: Important Notes -->
          <div
            class="col-span-12 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto"
          >
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-auto">
                Discuss Packets
              </h2>
              <button
                data-carousel="important-notes"
                data-target="prev"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="prevImportantNotes"
              >
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
              <button
                data-carousel="important-notes"
                data-target="next"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="nextImportantNotes"
              >
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </div>
            <div class="mt-5 intro-x" v-if="pagedata.discuss.length > 0">
              <div class="box zoom-in">
                <TinySlider ref-key="importantNotesRef">
                  <div v-for="(packet, index) in pagedata.discuss" :key="index">
                    <div class="p-5">
                      <table class="table-report w-full">
                        <thead>
                          <tr>
                            <th class="text-center whitespace-nowrap">#</th>
                            <th class="text-center whitespace-nowrap">Vep No</th>
                            <th class="text-center whitespace-nowrap">Number</th>
                            <th class="text-center whitespace-nowrap">Weight</th>
                            <th class="text-center whitespace-nowrap">Pwt</th>
                            <th class="text-center whitespace-nowrap">C_Wt</th>
                            <th class="text-center whitespace-nowrap">C_Shp</th>
                            <th class="text-center whitespace-nowrap">C_Clr</th>
                            <th class="text-center whitespace-nowrap">C_Prt</th>
                            <th class="text-center whitespace-nowrap">C_Cut</th>
                            <th class="text-center whitespace-nowrap">C_Pol</th>
                            <th class="text-center whitespace-nowrap">C_Sym</th>
                            <th class="text-center whitespace-nowrap">C_FL</th>
                            <th class="text-center whitespace-nowrap">C_TN</th>
                            <th class="text-center whitespace-nowrap">C_Stn</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="intro-x"
                          >
                            <td class="py-2 text-center whitespace-nowrap">{{ index + 1 }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.vepari_no }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.number }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.weight }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.admin_pwt > 0 ? packet.admin_pwt : packet.pwt }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.receive_weight }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.shape }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.color }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.purity }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.cut }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.polish }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.symmetry }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.fluerocent }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.tension }}</td>
                            <td class="py-2 text-center whitespace-nowrap">{{ packet.stone }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="text-gray-600 text-justify mt-1">
                        <span class="font-medium">Remark :-   </span> {{packet.discuss_remark}}
                      </div>
                    </div>
                  </div>
                </TinySlider>
              </div>
            </div>
            <div class="mt-5 intro-x" v-else>
              <div class="box zoom-in p-4">No Packets In Discuss Now!</div></div>
          </div>
          <!-- END: Important Notes -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive, toRef } from 'vue'
import ReportLineChart from '@/components/line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart
  },
  setup() {
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }
    const pagedata = reactive({
      headings: [],
      size: 5,
      currerrent_month_packets: null,
      prev_month_packets: null,
      party_growth: [],
      last_year_party_growth: [],
      discuss: [],
      processing: [],
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      parties: [],
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    onMounted(() => {
      PartyDashBoard()
      getAvailPkt()
    })
    function PartyDashBoardurl() {
        axios({
          url: url + `&size${pagedata.size}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.headings = res.data.headings[0]
          pagedata.currerrent_month_packets = res.data.currerrent_month_packets
          pagedata.prev_month_packets = res.data.prev_month_packets
          pagedata.party_growth = res.data.party_growth
          pagedata.last_year_party_growth = res.data.last_year_party_growth
          pagedata.discuss = res.data.discuss
          pagedata.processing = res.data.processing.data
          pagedata.pagelinks = res.data.processing.links
          pagedata.prev_page_url = res.data.processing.prev_page_url
          pagedata.next_page_url = res.data.processing.next_page_url
          pagedata.first_page_url = res.data.processing.first_page_url
          pagedata.last_page_url = res.data.processing.last_page_url
          pagedata.current_page = res.data.processing.current_page
          pagedata.last_page = res.data.processing.last_page
        })
    }
    function PartyDashBoard() {
        axios({
          url: `/dashboard/party?size=${pagedata.size}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.headings = res.data.headings[0]
          pagedata.currerrent_month_packets = res.data.currerrent_month_packets
          pagedata.prev_month_packets = res.data.prev_month_packets
          pagedata.party_growth = res.data.party_growth
          pagedata.last_year_party_growth = res.data.last_year_party_growth
          pagedata.discuss = res.data.discuss
          pagedata.processing = res.data.processing.data
          pagedata.pagelinks = res.data.processing.links
          pagedata.prev_page_url = res.data.processing.prev_page_url
          pagedata.next_page_url = res.data.processing.next_page_url
          pagedata.first_page_url = res.data.processing.first_page_url
          pagedata.last_page_url = res.data.processing.last_page_url
          pagedata.current_page = res.data.processing.current_page
          pagedata.last_page = res.data.processing.last_page
        })
    }
    function getAvailPkt() {
       axios({
        url: `/report/party_report`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.parties
      })
    }
    return {
      pagedata,
      pagelinks,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      PartyDashBoard,
      PartyDashBoardurl
    }
  }
})
</script>
