<template>
  <div>
    <div class="pos intro-y grid">
      <h2 class="text-lg font-medium mr-auto">Party Packet Report</h2>
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 border-r border-b text-xs whitespace-nowrap"></th>
                <th class="py-2 border-r border-b text-xs whitespace-nowrap"></th>
                <th class="py-2 border-r border-b border-r border-l text-xs whitespace-nowrap" colspan="2">In Inward</th>
                <th class="py-2 border-r border-b border-r text-xs whitespace-nowrap" colspan="3">Stock</th>
                <th class="py-2 border-r border-b text-xs whitespace-nowrap" colspan="4">Delivered</th>
                <th class="py-2 border-r border-b text-xs whitespace-nowrap" colspan="4">Total</th>
              </tr>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 border-r text-xs whitespace-nowrap">#</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Party</th>
                <th class="py-2 border-r text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Rough Wt</th>
                <th class="py-2 border-r text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Rough Wt</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Polish Wt</th>
                <th class="py-2 border-r text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Rough Wt</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Polish Wt</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Final Polish</th>
                <th class="py-2 border-r text-xs border-l whitespace-nowrap">Packets</th>
                <th class="py-2 border-r text-xs whitespace-nowrap">Rough Wt</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-gray-200 text-gray-700">
                <td class="py-2 border-r border-b text-xs text-center"></td>
                <td class="py-2 border-r border-b text-xs text-center"></td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_inpurchase_packets ? pagedata.total.total_inpurchase_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_inpurchase_weight ? ($h.myFloat(pagedata.total.total_inpurchase_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_stock_packets ? pagedata.total.total_stock_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_stock_weight ? ($h.myFloat(pagedata.total.total_stock_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_stock_polish_weight ? ($h.myFloat(pagedata.total.total_stock_polish_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_packets ? pagedata.total.total_delivered_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_issue_weight ? ($h.myFloat(pagedata.total.total_delivered_issue_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_packets_polish_weight ? ($h.myFloat(pagedata.total.total_delivered_packets_polish_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_packets_weight ? ($h.myFloat(pagedata.total.total_delivered_packets_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_issue_weight ? ($h.myFloat(pagedata.total.total_issue_weight) + ' Ct') : '-' }}</td>
              </tr>
              <tr v-for="(item, index) in pagedata.items" :key="index" class="border-b hover:bg-gray-200 p-0 cursor-pointer">
                <td class="py-2 border-r border-b text-xs text-center">{{ index + 1 }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.first_name ? item.first_name : '-' }} {{ item.last_name ? item.last_name : '' }} ({{ item.firm_name ? item.firm_name : '' }})</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_inpurchase_packets ? item.total_inpurchase_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_inpurchase_weight ? ($h.myFloat(item.total_inpurchase_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_stock_packets ? item.total_stock_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_stock_weight ? ($h.myFloat(item.total_stock_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_stock_polish_weight ? ($h.myFloat(item.total_stock_polish_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_delivered_packets ? item.total_delivered_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_delivered_issue_weight ? ($h.myFloat(item.total_delivered_issue_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_delivered_packets_polish_weight ? ($h.myFloat(item.total_delivered_packets_polish_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_delivered_packets_weight ? ($h.myFloat(item.total_delivered_packets_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_packets ? item.total_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ item.total_issue_weight ? ($h.myFloat(item.total_issue_weight) + ' Ct') : '-' }}</td>
              </tr>
              <tr class="bg-gray-200 text-gray-700">
                <td class="py-2 border-r border-b text-xs text-center"></td>
                <td class="py-2 border-r border-b text-xs text-center"></td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_inpurchase_packets ? pagedata.total.total_inpurchase_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_inpurchase_weight ? ($h.myFloat(pagedata.total.total_inpurchase_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_stock_packets ? pagedata.total.total_stock_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_stock_weight ? ($h.myFloat(pagedata.total.total_stock_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_stock_polish_weight ? ($h.myFloat(pagedata.total.total_stock_polish_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_packets ? pagedata.total.total_delivered_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_issue_weight ? ($h.myFloat(pagedata.total.total_delivered_issue_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_packets_polish_weight ? ($h.myFloat(pagedata.total.total_delivered_packets_polish_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_delivered_packets_weight ? ($h.myFloat(pagedata.total.total_delivered_packets_weight) + ' Ct') : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                <td class="py-2 border-r border-b text-xs text-center">{{ pagedata.total.total_issue_weight ? ($h.myFloat(pagedata.total.total_issue_weight) + ' Ct') : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import { defineComponent, onMounted, reactive } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      items: [],
      total: [],
    })
    onMounted(() => {
      getAvailPkt()
    })
    function getAvailPkt() {
       axios({
        url: `api/report/get_party_packet_details`,
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.items = res.data.items
        pagedata.total = res.data.total
      })
    }
    return {
      pagedata
    }
  }
})
</script>
