/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import Master from '../views/master/Main.vue'
import AdminDashBoard from '../views/AdminDashboard/Main.vue'

import Login from '../views/login/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'

// Master Details
import Parameters from '../views/master/parameters.vue'
import InwardColor from '../views/master/purchase-color-view/Main.vue'
import Machine from '../views/master/machine-view/Main.vue'
import Color from '../views/master/color-view/Main.vue'
import Cut from '../views/master/cut-view/Main.vue'
import Purity from '../views/master/purity-view/Main.vue'
import Polish from '../views/master/polish-view/Main.vue'
import Shape from '../views/master/shape-view/Main.vue'
import Summary from '../views/master/symmetry-view/Main.vue'
import Fluerocent from '../views/master/fluerocent-view/Main.vue'
import Tension from '../views/master/tension-view/Main.vue'
import Stone from '../views/master/stone-view/Main.vue'
import LabCertificate from '../views/master/lab-certificate-view/Main.vue'
import Tabl from '../views/master/tabl-view/Main.vue'
import Height from '../views/master/height-view/Main.vue'
import Length from '../views/master/length-view/Main.vue'
import Width from '../views/master/width-view/Main.vue'
import Charni from '../views/master/charni-view/Main.vue'
import Ratio from '../views/master/ratio-view/Main.vue'
import Back from '../views/master/back-view/Main.vue'
import BackCreate from '../views/master/back-view/create.vue'
import Process from '../views/master/process-master/data.vue'
import CUProcess from '../views/master/process-master/create.vue'
import ProcessRates from '../views/master/process-master/rates.vue'
import Department from '../views/master/department-master/Main.vue'
import CostingRates from '../views/master/CostingRates.vue'
import MarginCosting from '../views/master/margin-costing-view/Main.vue'

//User Masters
import EmployeeMaster from '../views/user-master/employee-master/Main.vue'
import EmployeeMasterCreate from '../views/user-master/employee-master/create_user.vue'
import EmployeeMasterUpdate from '../views/user-master/employee-master/create_user.vue'
import ManagerMaster from '../views/user-master/manager-master/Main.vue'
import ManagerMasterCreate from '../views/user-master/manager-master/create_user.vue'
import ManagerMasterUpdate from '../views/user-master/manager-master/create_user.vue'
import ManagerMasterRates from '../views/user-master/manager-master/rates.vue'
import VendorMaster from '../views/user-master/vendor-master/Main.vue'
import VendorMasterCreate from '../views/user-master/vendor-master/create_user.vue'
import VendorMasterUpdate from '../views/user-master/vendor-master/create_user.vue'
import VendorMasterRates from '../views/user-master/vendor-master/rates.vue'
import JoberMaster from '../views/user-master/jober-master/Main.vue'
import JoberMasterCreate from '../views/user-master/jober-master/create_user.vue'
import JoberMasterUpdate from '../views/user-master/jober-master/create_user.vue'
import JoberMasterRates from '../views/user-master/jober-master/rates.vue'
import PlannerMaster from '../views/user-master/planner-master/Main.vue'
import PlannerMasterCreate from '../views/user-master/planner-master/create_user.vue'
import PlannerMasterUpdate from '../views/user-master/planner-master/create_user.vue'

// Rates Master
import EmployeeRates from '../views/rate-master/employee-rates/Main.vue'
import EmployeeRatesCreate from '../views/rate-master/employee-rates/create_rate.vue'
import EmployeeRatesUpdate from '../views/rate-master/employee-rates/create_rate.vue'
import ManagerRatesUser from '../views/rate-master/manager-rates/user.vue'
import ManagerRates from '../views/rate-master/manager-rates/Main.vue'
import ManagerRatesCreate from '../views/rate-master/manager-rates/create_rate.vue'
import ManagerRatesUpdate from '../views/rate-master/manager-rates/update_rate.vue'
import VendorRatesUser from '../views/rate-master/vendor-rates/user.vue'
import VendorRates from '../views/rate-master/vendor-rates/Main.vue'
import VendorRatesCreate from '../views/rate-master/vendor-rates/create_rate.vue'
import VendorRatesUpdate from '../views/rate-master/vendor-rates/update_rate.vue'
import JoberRatesUser from '../views/rate-master/jober-rates/user.vue'
import JoberRates from '../views/rate-master/jober-rates/Main.vue'
import JoberRatesCreate from '../views/rate-master/jober-rates/create_rate.vue'
import JoberRatesUpdate from '../views/rate-master/jober-rates/update_rate.vue'
import RepoRates from '../views/rate-master/repo-rates/Main.vue'
import ManualRateCreate from '../views/rate-master/manual-rates/create.vue'
import ManualRateList from '../views/rate-master/manual-rates/Main.vue'

// Lot Inward
import Inward from '../views/Inward/Main.vue'
import InwardCreate from '../views/Inward/create.vue'
import InwardUpdate from '../views/Inward/create.vue'
import RoughInward from '../views/Inward/rough_main.vue'
import RoughInwardAdmin from '../views/Inward/rough_main_admin.vue'
import RoughInwardTransaction from '../views/RoughProcess/Main.vue'
import RoughInwardSingle from '../views/Inward/rough_view.vue'

// Transactions
import SingleIssue from '../views/PacketTransaction/single-issue/Main.vue'
import PacketListView from '../views/PacketTransaction/packet-details/second.vue'
import MultipleIssue from '../views/PacketTransaction/multiple-issue/Main.vue'
import PlannerMultipleIssue from '../views/PacketTransaction/planner-multiple-issue/Main.vue'
import AdminMultiplePlanning from '../views/PacketTransaction/admin-planning-view/Main.vue'
import PlannerDonePackets from '../views/PacketTransaction/planner-multiple-transfer/Main.vue'
import MultipleReceive from '../views/PacketTransaction/multiple-receive/Main.vue'
import LSReceive from '../views/PacketTransaction/ls-recut-receive/recut.vue'
import LSReceiveNew from '../views/PacketTransaction/ls-recut-receive/recut-new.vue'
import LSMakeable from '../views/PacketTransaction/ls-recut-receive/makeable.vue'
import LabReceive from '../views/PacketTransaction/lab-receive/Main.vue'
import LSPartyReceive from '../views/PacketTransaction/ls-receive/PartyMain.vue'
import LSUpdate from '../views/PacketTransaction/ls-recut-receive/ls.vue'
import PartyRoughReceive from '../views/PacketTransaction/receive/FullProcessRough.vue'
import DiscussTransaction from '../views/PacketTransaction/discuss-transaction/Main.vue'
import TransactionView from '../views/PacketTransaction/transaction-view/Main.vue'
import TransactionDetailsView from '../views/PacketTransaction/transaction-view/transaction_details.vue'
import ReceiveTransactionView from '../views/PacketTransaction/receive-transaction-view/Main.vue'
import ReceiveTransactionDetailsView from '../views/PacketTransaction/receive-transaction-view/transaction_details.vue'
import JobDoneView from '../views/JobDone/Main.vue'
import JobDoneDetailsView from '../views/JobDone/detail.vue'
import JobDoneCreateView from '../views/JobDone/create.vue'
import DiscussTransactions from '../views/PacketDiscuss/discuss_transaction.vue'
import DiscussStocks from '../views/PacketDiscuss/discuss_stock.vue'

// Final Polish
import FinalPolishStockList from '../views/PacketTransaction/final-polish-transfer/Main.vue'
import FinalPolishStockLive from '../views/PacketTransaction/final-polish-transfer/Stock.vue'


// Notification Section
import EmployeeRateNotification from '../views/notification-section/employee-rates/Main.vue'
import CreateJoberRateNotification from '../views/notification-section/employee-rates/jober.vue'
import CreateEmployeeRateNotification from '../views/notification-section/employee-rates/create.vue'
import ManagerRateNotification from '../views/notification-section/manager-rates/Main.vue'
import CreateManagerRateNotification from '../views/notification-section/manager-rates/create.vue'
import ParameterEditNotification from '../views/notification-section/parameter-edit/Main.vue'
import ParameterEditNotificationView from '../views/notification-section/parameter-edit/edit.vue'
import ParameterUpdateNotification from '../views/notification-section/parameter-update/Main.vue'

// Closing View
import ManagerClosingsView from '../views/closing-view/manager-closings/Main.vue'
import AdminClosingsView from '../views/closing-view/admin-closings/Main.vue'

// Rough To Makeable Transfer View
import MakeableTransferView from '../views/makeable-view/Main.vue'
import RoughTransferView from '../views/makeable-view/Rough.vue'

// Rough To Makeable Transfer View
import UrgentTransferView from '../views/urgent-view/Main.vue'

// Rough To Makeable Transfer View
import OutwordView from '../views/outword-view/Main.vue'

import UpdateProfile from "../views/update-profile/Main.vue";
import ChangePassword from "../views/change-password/Main.vue";

// Report Details
import userCostingReport from '../views/Report/costing-report/Main.vue'
import packetProcessReport from '../views/Report/process-report/Main.vue'
import ProcessNewStockReport from '../views/Report/process-stock/Main.vue'
import RTPReport from '../views/Report/rtp-report/Main.vue'
import KapanDoneReport from '../views/Report/kapan-done-report/Main.vue'
import LABReport from '../views/Report/lab-report/Main.vue'
import KapanReport from '../views/Report/kapan-report/Main.vue'
import PacketStockSummary from '../views/Report/stock-summary/Main.vue'
import packetStockReport from '../views/Report/stock-report/Main.vue'
import packetStockPartyReport from '../views/Report/stock-report-party/Main.vue'
import processStockReport from '../views/Report/process-stock-report/Main.vue'
import departmentStockReport from '../views/Report/department-stock-report/Main.vue'
import managersStockReport from '../views/Report/manager-stock-report/Main.vue'
import partyStockReport from '../views/Report/party-stock-report/Main.vue'
import partyPacketReport from '../views/Report/party-packet-report/Main.vue'
import MakeableOutwardReport from '../views/Report/makeable-outward-report/Main.vue'
import RoughOutwardReport from '../views/Report/rough-outward-report/Main2.vue'
import PlannerReport from '../views/Report/planner-report/Main.vue'
import StockSearchReport from '../views/Report/stock-search-report/Main.vue'
import ReportPlanningPrediction from '../views/Report/prediction-report/Main.vue'
//
import ProcessTransfer from '../views/processTransfer/Main.vue'
import Packets from '../views/packets/Main.vue'
import ManagerDashboard from '../views/ManagerDash/Main.vue'
import PlannerDashboard from '../views/Planner/dashboard.vue'
import LsbsDashboard from '../views/Lsbs/dashboard.vue'
import CheckerDashboard from '../views/Checker/dashboard.vue'
import TransferedPackets from '../views/ManagerTransferedPacket/TransferedPacket.vue'
import ReceivedPackets from '../views/ManagerTransferedPacket/ReceivedPacket.vue'
import ManagerPackets from '../views/ManagerDash/Packets.vue'
import ReceivePackets from '../views/ManagerDash/receive.vue'
import SinglePacket from '../views/ManagerDash/singlepkt.vue'
import JoberPackets from '../views/JoberDashboard/Packets.vue'
import JoberReceivePackets from '../views/JoberDashboard/receive.vue'
import JoberSinglePacket from '../views/JoberDashboard/singlepkt.vue'
import PartyDashboard from '../views/PartyDashboard/Dashboard.vue'
import JoberDashboard from '../views/JoberDashboard/Main.vue'
import JoberPacketRequests from '../views/JoberDashboard/joberpacketrequests.vue'
import RateRequest from '../views/AdminNotification/rateRequest.vue'
import RateRequestManager from '../views/AdminNotification/rateRequestManager.vue'
import RateRequestJober from '../views/AdminNotification/rateRequestJober.vue'
import CreateRateRequest from '../views/AdminNotification/createRate.vue'
import CreateRoughRateRequest from '../views/AdminNotification/createRoughRate.vue'
import ParamChangeRequest from '../views/AdminNotification/paramChangeRequest.vue'
import editParameter from '../views/AdminNotification/paramChangeMdlRequest.vue'
import editSinglePageParameter from '../views/AdminNotification/singlepkt.vue'
import AdminDiscuss from '../views/Discuss/admin_discuss.vue'
import PartyDiscuss from '../views/Discuss/party_discuss.vue'
import ManagerDiscuss from '../views/Discuss/manager_discuss.vue'
import ManagerRateChange from '../views/ManagerNotification/manager_rate_change.vue'
import ManagerParamChange from '../views/ManagerNotification/manager_param_change.vue'
import DiscussPacketDetails from '../views/Discuss/discuss_packet_data.vue'
import JoberDeliverable from '../views/JoberDashboard/jober_deliverable.vue'
import ManagerReceivePacket from '../views/ManagerDash/receive_deliverable.vue'
import AdminClosing from '../views/Closing/admin_closing.vue'
import ManagerClosing from '../views/Closing/manager_closing.vue'
import AdminUrgentPackets from '../views/AdminDashboard/urgent_packets.vue'
import ManagerAllUrgentPackets from '../views/UrgentPackets/manager_all_urgent_packets.vue'
import ManagerExternalUrgentPackets from '../views/UrgentPackets/manager_external_urgent_packets.vue'
import ManagerExternalPackets from '../views/ManagerDash/external_packets.vue'
import AdminOutWord from '../views/AdminDashboard/outword.vue'
import ManagerOutWord from '../views/ManagerDash/outword.vue'
import PlanCheckPackets from '../views/planner_checker/packets.vue'
import PlanCheckPlans from '../views/planner_checker/plans.vue'
import LsbsPackets from '../views/planner_checker/lsbs_packets.vue'
import LsbsPlans from '../views/planner_checker/lsbs_create_update_plan_packet.vue'
import ManPlanCheckPackets from '../views/planner_checker/manager_packets.vue'
import ManPlanCheckPlans from '../views/planner_checker/manager_plans.vue'
import CUPlan from '../views/planner_checker/create_update_plan.vue'
import PartyOutWord from '../views/PartyDashboard/outword.vue'
import ManagerLsBsPackets from '../views/Lsbs/manager_lsbs_packets.vue'
import RoughToMakeable from '../views/RoughTransferedPacket/makeable.vue'
import RoughToRough from '../views/RoughTransferedPacket/rough.vue'
import PacketReport from '../views/admin_report/packet_report.vue'
import StockReport from '../views/admin_report/packet_stock.vue'
import ProcessReport from '../views/admin_report/process_report.vue'
import DepartmentReport from '../views/admin_report/department_report.vue'
import EmployeeReport from '../views/admin_report/employee_report.vue'
import OutwardReport from '../views/admin_report/outward_report.vue'
import PartyReport from '../views/admin_report/party_report.vue'
import RoughReport from '../views/admin_report/rough_report.vue'
import ManagerStockReport from '../views/manager_report/packet_stock.vue'
import ComparisonReport from '../views/Report/comparison-report/Main.vue'
import RejectionReport from '../views/Report/rejection-report/Main.vue'
import CostingNotification from '../views/AdminNotification/costing_notification.vue'
import CostingDetail from '../views/AdminNotification/costing_detail.vue'
import AuthRequired from '@/utils/AuthRequired'
const routes = [
  {
    path: '/',
    component: SideMenu,
    beforeEnter: AuthRequired,
    meta: ['Dashboard'],
    children: [
      {
        path: '/',
        name: 'admin-dashboard',
        meta: ['Dashboard', 'Admin'],
        component: AdminDashBoard
      },
      {
        path: "update-profile",
        name: "update-profile",
        meta: ['Account', 'Profile'],
        component: UpdateProfile
      },
      {
        path: "change-password",
        name: "change-password",
        meta: ['Account', 'Change Password'],
        component: ChangePassword
      },
      // Master
      {
        path: '/master',
        name: 'master',
        meta: ['Master'],
        component: Master
      },
      {
        path: '/master/parameters',
        name: 'parameters',
        component: Parameters,
        children: [

          {
            path: '/master/color',
            name: 'color',
            meta: ['Master', 'Color'],
            component: Color
          },
          {
            path: '/master/inward-color',
            name: 'inward-color',
            component: InwardColor
          },
          {
            path: '/master/machine',
            name: 'machine',
            component: Machine
          },
          {
            path: '/master/cut',
            name: 'cut',
            meta: ['Master', 'Cut'],
            meta: 'Cut Master',
            component: Cut
          },
          {
            path: '/master/purity',
            name: 'purity',
            meta: ['Master', 'Purity'],
            component: Purity
          },
          {
            path: '/master/shape',
            name: 'shape',
            meta: ['Master', 'Shape'],
            component: Shape
          },
          {
            path: '/master/symmetry',
            name: 'symmetry',
            meta: ['Master', 'Symmetry'],
            component: Summary
          },
          {
            path: '/master/lab-certificate',
            name: 'lab-certificate',
            meta: ['Master', 'LAB Certificate'],
            component: LabCertificate
          },
          {
            path: '/master/tabl',
            name: 'tabl',
            meta: ['Master', 'Table'],
            component: Tabl
          },
          {
            path: '/master/height',
            name: 'height',
            meta: ['Master', 'Height'],
            component: Height
          },
          {
            path: '/master/length',
            name: 'length',
            meta: ['Master', 'Length'],
            component: Length
          },
          {
            path: '/master/width',
            name: 'width',
            meta: ['Master', 'Width'],
            component: Width
          },
          {
            path: '/master/charni',
            name: 'charni',
            meta: ['Master', 'Charni'],
            component: Charni
          },
          {
            path: '/master/ratio',
            name: 'ratio',
            meta: ['Master', 'Ratio'],
            component: Ratio
          },
          {
            path: '/master/fluerocent',
            name: 'fluerocent',
            meta: ['Master', 'Fluerocent'],
            component: Fluerocent
          },
          {
            path: '/master/stone',
            name: 'stone',
            meta: ['Master', 'Stone'],
            component: Stone

          },
          {
            path: '/master/polish',
            name: 'polish',
            meta: ['Master', 'Polish'],
            component: Polish

          },
          {
            path: '/master/tension',
            name: 'tension',
            meta: ['Master', 'Tension'],
            component: Tension

          },
          {
            path: '/master/back',
            name: 'back',
            meta: ['Master', 'Discount'],
            component: Back
          },
          {
            path: '/master/back/create',
            name: 'create-back-discount',
            meta: ['Master', 'Discount', 'Create'],
            component: BackCreate
          },
          {
            path: '/master/back/update/:id',
            name: 'update-back-discount',
            meta: ['Master', 'Discount', 'Update'],
            component: BackCreate
          },
        ]
      },
      {
        path: '/master/costing-rates',
        name: 'costing-rates',
        meta: ['Master', 'Costing', 'Rates'],
        component: CostingRates
      },
      {
        path: '/master/margin-costing',
        name: 'margin-costing-rates',
        meta: ['Master', 'Margin', 'Costing'],
        component: MarginCosting
      },
      {
        path: '/master/department',
        name: 'department',
        meta: ['Master', 'Department'],
        component: Department
      },
      {
        path: '/master/process',
        name: 'process',
        meta: ['Master', 'Process'],
        component: Process
      },
      {
        path: '/master/process/create',
        name: 'process-create',
        meta: ['Master', 'Process', 'Create'],
        component: CUProcess
      },
      {
        path: '/master/process/update/:id',
        name: 'update_process',
        meta: ['Master', 'Process', 'Update'],
        component: CUProcess
      },
      {
        path: '/master/process/rates/:id',
        name: 'update_process_rates',
        meta: ['Master', 'Process', 'Rates'],
        component: ProcessRates
      },
      // User Master
      {
        path: '/master/manager',
        name: 'manager-details',
        meta: ['Accounts', 'Manager', 'List'],
        component: ManagerMaster
      },
      {
        path: '/master/manager/create',
        name: 'create-manager-details',
        meta: ['Accounts', 'Manager', 'Create'],
        component: ManagerMasterCreate
      },
      {
        path: '/master/manager/update/:id',
        name: 'update-manager-details',
        meta: ['Accounts', 'Manager', 'Update'],
        component: ManagerMasterUpdate
      },
      {
        path: '/master/manager/rates/:id',
        name: 'update-manager-rate',
        meta: ['Accounts', 'Manager', 'Rates'],
        component: ManagerMasterRates
      },
      {
        path: '/master/employee',
        name: 'employee-details',
        meta: ['Accounts', 'Employee', 'List'],
        component: EmployeeMaster
      },
      {
        path: '/master/employee/create',
        name: 'create-employee-details',
        meta: ['Accounts', 'Employee', 'Create'],
        component: EmployeeMasterCreate
      },
      {
        path: '/master/employee/update/:id',
        name: 'update-employee-details',
        meta: ['Accounts', 'Employee', 'Update'],
        component: EmployeeMasterUpdate
      },
      {
        path: '/master/vendor',
        name: 'vendor-details',
        meta: ['Accounts', 'Vendor', 'List'],
        component: VendorMaster
      },
      {
        path: '/master/vendor/create',
        name: 'create-vendor-details',
        meta: ['Accounts', 'Vendor', 'Create'],
        component: VendorMasterCreate
      },
      {
        path: '/master/vendor/update/:id',
        name: 'update-vendor-details',
        meta: ['Accounts', 'Vendor', 'Update'],
        component: VendorMasterUpdate
      },
      {
        path: '/master/vendor/rates/:id',
        name: 'update-vendor-item-rates',
        meta: ['Accounts', 'Vendor', 'Rates'],
        component: VendorMasterRates
      },
      {
        path: '/master/jober',
        name: 'jober-details',
        meta: ['Accounts', 'Job Work', 'List'],
        component: JoberMaster
      },
      {
        path: '/master/jober/create',
        name: 'create-jober-details',
        meta: ['Accounts', 'Job Work', 'Create'],
        component: JoberMasterCreate
      },
      {
        path: '/master/jober/update/:id',
        name: 'update-jober-details',
        meta: ['Accounts', 'Job Work', 'Update'],
        component: JoberMasterUpdate
      },
      {
        path: '/master/jober/rates/:id',
        name: 'update-job-work-rates',
        meta: ['Accounts', 'Job Work', 'Rates'],
        component: JoberMasterRates
      },
      {
        path: '/master/planner',
        name: 'planner-details',
        meta: ['Accounts', 'Planner', 'List'],
        component: PlannerMaster
      },
      {
        path: '/master/planner/create',
        name: 'create-planner-details',
        meta: ['Accounts', 'Planner', 'Create'],
        component: PlannerMasterCreate
      },
      {
        path: '/master/planner/update/:id',
        name: 'update-planner-details',
        meta: ['Accounts', 'Planner', 'Update'],
        component: PlannerMasterUpdate
      },
      // Rate Master
      {
        path: '/master/rates/repo',
        name: 'repo-rates',
        meta: ['Rates', 'Repo', 'Rates'],
        component: RepoRates
      },
      {
        path: '/master/rates/manual/create',
        name: 'manual-rate-create',
        meta: ['Rates', 'Manual', 'Rates'],
        component: ManualRateCreate
      },
      {
        path: '/master/rates/manual/list',
        name: 'manual-rate-list',
        meta: ['Rates', 'Manual', 'Rates List'],
        component: ManualRateList
      },
      {
        path: '/master/rates/employee',
        name: 'employee-rates',
        meta: ['Process', 'Rates'],
        component: EmployeeRates

      },
      {
        path: '/master/rates/employee/create',
        name: 'create-employee-rates',
        component: EmployeeRatesCreate

      },
      {
        path: '/master/rates/employee/update/:id',
        name: 'update-employee-rates',
        component: EmployeeRatesUpdate

      },
      {
        path: '/master/rates/manager',
        name: 'manager-rates-user',
        component: ManagerRatesUser

      },
      {
        path: '/master/rates/manager/:id',
        name: 'manager-rates',
        component: ManagerRates

      },
      {
        path: '/master/rates/manager/create/:id',
        name: 'create-manager-rates',
        component: ManagerRatesCreate

      },
      {
        path: '/master/rates/manager/update/:id',
        name: 'update-manager-rates',
        component: ManagerRatesUpdate

      },
      {
        path: '/master/rates/vendor',
        name: 'vendor-rates-user',
        component: VendorRatesUser

      },
      {
        path: '/master/rates/vendor/:id',
        name: 'vendor-rates',
        component: VendorRates

      },
      {
        path: '/master/rates/vendor/create/:id',
        name: 'create-vendor-rates',
        component: VendorRatesCreate

      },
      {
        path: '/master/rates/vendor/update/:id',
        name: 'update-vendor-rates',
        component: VendorRatesUpdate

      },
      {
        path: '/master/rates/jober',
        name: 'jober-rates-user',
        component: JoberRatesUser

      },
      {
        path: '/master/rates/jober/:id',
        name: 'jober-rates',
        component: JoberRates

      },
      {
        path: '/master/rates/jober/create/:id',
        name: 'create-jober-rates',
        component: JoberRatesCreate

      },
      {
        path: '/master/rates/jober/update/:id',
        name: 'update-jober-rates',
        component: JoberRatesUpdate

      },
      {
        path: 'process-transfer',
        name: 'ProcessTransfer',
        component: ProcessTransfer
      },
      //Inward
      {
        path: '/purchase',
        name: 'purchase-view',
        component: Inward
      },
      {
        path: '/purchase/create',
        name: 'purchase-create',
        component: InwardCreate
      },
      {
        path: '/purchase/update/:id',
        name: 'purchase-update',
        component: InwardUpdate
      },
      {
        path: '/purchase/rough',
        name: 'rough-purchase-view',
        component: RoughInward
      },
      {
        path: '/purchases/rough',
        name: 'rough-purchase-view-admin',
        component: RoughInwardAdmin
      },
      {
        path: '/purchase/rough/issue',
        name: 'rough-purchase-transaction',
        component: RoughInwardTransaction
      },
      {
        path: '/purchase/rough/:id',
        name: 'rough-purchase-single',
        component: RoughInwardSingle
      },
      //Transaction
      {
        path: '/packet-details',
        name: 'single-packet-transaction',
        component: SingleIssue
      },
      {
        path: '/packets',
        name: 'packet-lise-view',
        component: PacketListView
      },
      {
        path: '/multiple/packet/issue',
        name: 'multiple-issue-transaction',
        component: MultipleIssue
      },
      {
        path: '/multiple/packet/planner',
        name: 'planner-multiple-issue-transaction',
        component: PlannerMultipleIssue
      },
      {
        path: '/multiple/packet/plans',
        name: 'admin-multiple-packet-plans',
        component: AdminMultiplePlanning
      },
      {
        path: '/multiple/packet/plan-done',
        name: 'planner-multiple-transfer',
        component: PlannerDonePackets
      },
      {
        path: '/multiple/packet/receive',
        name: 'multiple-receive-transaction',
        component: MultipleReceive
      },
      {
        path: '/ls/packet/receive',
        name: 'ls-receive-transaction',
        component: LSReceiveNew
      },
      {
        path: '/ls/packet/makeable-transfer',
        name: 'ls-makeable-transfer',
        component: LSMakeable
      },
      // {
      //   path: '/ls/packet/receive',
      //   name: 'ls-receive-transaction',
      //   component: LSReceive
      // },
      {
        path: '/ls/packet/udate',
        name: 'ls-update-carats',
        component: LSUpdate
      },
      {
        path: '/lab/receive',
        name: 'lab-receive',
        component: LabReceive
      },
      {
        path: '/ls-party/packet/',
        name: 'ls-receive-party-transaction',
        component: LSPartyReceive
      },
      {
        path: '/full-process/packet/receive',
        name: 'party-receive-rough-transaction',
        component: PartyRoughReceive
      },
      {
        path: '/packets/discuss',
        name: 'discuss-transaction',
        component: DiscussTransaction
      },
      {
        path: '/packets/transaction',
        name: 'transaction-history',
        component: TransactionView
      },
      {
        path: '/packets/transaction/:id',
        name: 'transaction-details',
        component: TransactionDetailsView
      },
      {
        path: '/packets/receive/transaction',
        name: 'receive-transaction-history',
        component: ReceiveTransactionView
      },
      {
        path: '/packets/receive/transaction/:id',
        name: 'receive-transaction-details',
        component: ReceiveTransactionDetailsView
      },
      {
        path: '/packets/job-done/transaction',
        name: 'job-done-list',
        component: JobDoneView
      },
      {
        path: '/packets/job-done/transaction/:id',
        name: 'job-done-detail-view',
        component: JobDoneDetailsView
      },
      {
        path: '/packets/job-done/create',
        name: 'job-done-create',
        component: JobDoneCreateView
      },
      {
        path: '/packets/discuss/transactions',
        name: 'discuss-transactions',
        component: DiscussTransactions
      },
      {
        path: '/discuss/stock',
        name: 'discuss-stocks',
        component: DiscussStocks
      },
      //Notification Section
      {
        path: '/notification/employee',
        name: 'notification-employee',
        component: EmployeeRateNotification
      },
      {
        path: '/notification/create-employee-rate/:id',
        name: 'notification-create-employee-rate',
        component: CreateEmployeeRateNotification
      },
      {
        path: '/notification/create-jober-rate/:id',
        name: 'notification-create-jober-rate',
        component: CreateJoberRateNotification
      },
      {
        path: '/notification/create-manager-rate/:id',
        name: 'notification-create-manager-rate',
        component: CreateManagerRateNotification
      },
      {
        path: '/notification/manager',
        name: 'notification-manager',
        component: ManagerRateNotification
      },
      {
        path: '/notification/edit-param',
        name: 'notification-edit-param',
        component: ParameterEditNotification
      },
      {
        path: '/notification/edit-param/:id',
        name: 'notification-edit-param-view',
        component: ParameterEditNotificationView
      },
      {
        path: '/notification/update-param',
        name: 'notification-update-param',
        component: ParameterUpdateNotification
      },
      // Final Polish
      {
        path: '/final-polish/list',
        name: 'final-polish-stock-available',
        component: FinalPolishStockList
      },
      {
        path: '/final-polish/live',
        name: 'final-polish-stock-live',
        component: FinalPolishStockLive
      },
      // Closings
      {
        path: '/manager/closings',
        name: 'manager-closings-view',
        component: ManagerClosingsView
      },
      {
        path: '/admin/closings',
        name: 'admin-closings-view',
        component: AdminClosingsView
      },
      // Rough Transfer To Makeable
      {
        path: '/makeable-transfer',
        name: 'rough-to-makeable-view',
        component: MakeableTransferView
      },
      // Rough Transfer To Makeable
      {
        path: '/rough-transfer',
        name: 'makeable-to-rough-view',
        component: RoughTransferView
      },
      // Rough Urgent To Makeable
      {
        path: '/urgent-transfer',
        name: 'urgent-packets-view',
        component: UrgentTransferView
      },
      // Rough Transfer To Makeable
      {
        path: '/outword-packets',
        name: 'outword-packets-view',
        component: OutwordView
      },
      //Report Details
      {
        path: '/costing-report',
        name: 'user_costing_report',
        meta: ['Report', 'Costing'],
        component: userCostingReport
      },
      {
        path: '/process-report',
        name: 'user_process_report',
        meta: ['Report', 'Process'],
        component: packetProcessReport
      },
      {
        path: '/new-process-stock-report',
        name: 'process_stock_new_report',
        meta: ['Report', 'Process Stock'],
        component: ProcessNewStockReport
      },
      {
        path: '/rtp-report',
        name: 'rtp_report',
        meta: ['Report', 'Rough To Polish'],
        component: RTPReport
      },
      {
        path: '/kapan-done-report',
        name: 'kapan_done_report',
        meta: ['Report', 'Kapan Done'],
        component: KapanDoneReport
      },
      {
        path: '/lab-report',
        name: 'lab_report',
        meta: ['Report', 'LAB'],
        component: LABReport
      },
      {
        path: '/kapan-report',
        name: 'full_kapan_report',
        meta: ['Report', 'Full Kapan'],
        component: KapanReport
      },
      {
        path: '/report/planning/prediction',
        name: 'report_planning_prediction',
        meta: ['Report', 'Prediction'],
        component: ReportPlanningPrediction
      },
      {
        path: '/stock-report',
        name: 'user_stock_report',
        meta: ['Report', 'User Stock'],
        component: packetStockReport
      },
      {
        path: '/stock-symmary',
        name: 'packet_stock_summary',
        meta: ['Report', 'Stock Summary'],
        component: PacketStockSummary
      },
      {
        path: '/stock-report-party',
        name: 'user_stock_party_report',
        meta: ['Report', 'Party', 'Stock'],
        component: packetStockPartyReport
      },
      {
        path: '/process-stock-report',
        name: 'process_stock_report',
        meta: ['Report', 'Process', 'Stock'],
        component: processStockReport
      },
      {
        path: '/department-stock-report',
        name: 'department_stock_report',
        meta: ['Report', 'Department', 'Stock'],
        component: departmentStockReport
      },
      {
        path: '/manager-stock-report',
        name: 'manager_stock_report',
        meta: ['Report', 'Manager', 'Stock'],
        component: managersStockReport
      },
      {
        path: '/party-stock-report',
        name: 'party_stock_report',
        meta: ['Report', 'Party', 'Stock'],
        component: partyStockReport
      },
      {
        path: '/party-packet-report',
        name: 'party_packet_report',
        meta: ['Report', 'Party'],
        component: partyPacketReport
      },
      {
        path: '/makeable-outward-report',
        name: 'makeable_outward_report',
        meta: ['Report', 'Outward', 'Makeable'],
        component: MakeableOutwardReport
      },
      {
        path: '/rough-outward-report',
        name: 'rough_outward_report',
        meta: ['Report', 'Outward', 'Rough'],
        component: RoughOutwardReport
      },
      {
        path: '/plan-report',
        name: 'plan_done_report',
        meta: ['Report', 'Plan Done'],
        component: PlannerReport
      },
      {
        path: '/stock-search-report',
        name: 'stock_search_report',
        meta: ['Report', 'Stock Search'],
        component: StockSearchReport
      },
      {
        path: '/comparison-report',
        name: 'comparison_report',
        meta: ['Report', 'Comparison'],
        component: ComparisonReport
      },
      {
        path: '/rejection-report',
        name: 'rejection_report',
        meta: ['Report', 'Rejection'],
        component: RejectionReport
      },

      // Next
      {
        path: 'packet',
        name: 'process-packet',
        component: Packets
      },
      {
        path: '/manager-dashboard',
        name: 'manager-dashboard',
        component: ManagerDashboard
      },
      {
        path: '/issue-packets',
        name: 'ManagerPackets',
        component: ManagerPackets
      },
      {
        path: '/single',
        name: 'SinglePacket',
        component: SinglePacket
      },
      {
        path: '/receive-pkts',
        name: 'MultiplePackets',
        component: ReceivePackets
      },
      {
        path: '/jober-multiple-issue',
        name: 'jober-multiple-issue',
        component: JoberPackets
      },
      {
        path: '/jober-single-packet',
        name: 'jober-single-packet',
        component: JoberSinglePacket
      },
      {
        path: '/jober-multiple-receive',
        name: 'jober-multiple-receive',
        component: JoberReceivePackets
      },
      {
        path: '/transfered-packets',
        name: 'TransferedPackets',
        component: TransferedPackets
      },
      {
        path: '/received-packets',
        name: 'ReceivedPackets',
        component: ReceivedPackets
      },
      {
        path: '/party-dashboard',
        name: 'party-dashboard',
        component: PartyDashboard
      },
      {
        path: '/jober-dashboard',
        name: 'jober-dashboard',
        component: JoberDashboard
      },
      {
        path: '/jober-requests',
        name: 'jober-packet-request',
        component: JoberPacketRequests
      },
      {
        path: '/admin-rate-requests',
        name: 'rate-requests',
        component: RateRequest
      },
      {
        path: '/admin-rate-requests-manager',
        name: 'rate-requests-manager',
        component: RateRequestManager
      },
      {
        path: '/admin-rate-requests-jober',
        name: 'rate-requests-jober',
        component: RateRequestJober
      },
      {
        path: '/admin-request-create-rate/:id',
        name: 'create-employee-rate-request',
        component: CreateRateRequest
      },
      {
        path: '/admin-request-create-rate/:type/:id',
        name: 'create-rough-employee-rate-request',
        component: CreateRoughRateRequest
      },
      {
        path: '/admin-param-requests',
        name: 'param-change-requests',
        component: ParamChangeRequest
      },
      {
        path: '/param-change-mdl-request',
        name: 'param-change-mdl-request',
        component: editParameter
      },
      {
        path: '/param-change-mdl-request/:id',
        name: 'param-mdl-request',
        component: editSinglePageParameter
      },
      {
        path: '/admin-discuss',
        name: 'admin-discuss',
        component: AdminDiscuss
      },
      {
        path: '/party-discuss',
        name: 'party-discuss',
        component: PartyDiscuss
      },
      {
        path: '/manager-discuss',
        name: 'manager-discuss',
        component: ManagerDiscuss
      },
      {
        path: '/discuss-packet-data/:id',
        name: 'discuss-packet-data',
        component: DiscussPacketDetails
      },
      {
        path: '/manager-rate-change',
        name: 'manager-rate-change',
        component: ManagerRateChange
      },
      {
        path: '/manager-param-change',
        name: 'manager-param-change',
        component: ManagerParamChange
      },
      {
        path: '/jober-deliverable',
        name: 'jober-deliverable',
        component: JoberDeliverable
      },
      {
        path: '/manager-receive-packets',
        name: 'manager-receive-from-jober',
        component: ManagerReceivePacket
      },
      {
        path: '/admin-closing',
        name: 'admin-closing',
        component: AdminClosing
      },
      {
        path: '/manager-closing',
        name: 'manager-closing',
        component: ManagerClosing
      },
      {
        path: '/admin-urgent-packets',
        name: 'admin-urgent-packets',
        component: AdminUrgentPackets
      },
      {
        path: '/all-urgent-packets',
        name: 'manager-all-urgent-packets',
        component: ManagerAllUrgentPackets
      },
      {
        path: '/external-urgent-packets',
        name: 'manager-external-urgent-packets',
        component: ManagerExternalUrgentPackets
      },
      {
        path: '/manager-external-packets',
        name: 'manager-external-packets',
        component: ManagerExternalPackets
      },
      {
        path: '/admin-outword',
        name: 'admin-jobwork-done',
        component: AdminOutWord
      },
      {
        path: '/manager-outword',
        name: 'manager-jobwork-done',
        component: ManagerOutWord
      },
      {
        path: '/party-outword',
        name: 'party-jobwork-done',
        component: PartyOutWord
      },
      {
        path: '/planner-dashboard',
        name: 'planner-dashboard',
        component: PlannerDashboard
      },
      {
        path: '/lsbs-dashboard',
        name: 'lsbs-dashboard',
        component: LsbsDashboard
      },
      {
        path: '/checker-dashboard',
        name: 'checker-dashboard',
        component: CheckerDashboard
      },
      {
        path: '/plan-packets',
        name: 'plan-packets',
        component: PlanCheckPackets
      },
      {
        path: '/lsbs-plan-packets',
        name: 'lsbs-plan-packets',
        component: LsbsPackets
      },
      {
        path: '/packet-plans/:id',
        name: 'packet-plans',
        component: PlanCheckPlans
      },
      {
        path: '/lsbs-plans/:id',
        name: 'lsbs-plans',
        component: LsbsPlans
      },
      {
        path: '/manager-plan-packets',
        name: 'manager-plan-packets',
        component: ManPlanCheckPackets
      },
      {
        path: '/manager-packet-plans/:id',
        name: 'manager-packet-plans',
        component: ManPlanCheckPlans
      },
      {
        path: '/create-packet-plan/:id',
        name: 'create-packet-plan',
        component: CUPlan
      },
      {
        path: '/update-packet-plan/:id',
        name: 'update-packet-plan',
        component: CUPlan
      },
      {
        path: '/lsbs-packet-man',
        name: 'lsbs-packet-man',
        component: ManagerLsBsPackets
      },
      {
        path: '/rough-to-makeable',
        name: 'rough-to-makeable',
        component: RoughToMakeable
      },
      {
        path: '/rough-to-rough',
        name: 'rough-to-rough',
        component: RoughToRough
      },
      {
        path: '/packet-report',
        name: 'packet-report',
        component: PacketReport
      },
      // {
      //   path: '/stock-report',
      //   name: 'stock-report',
      //   component: StockReport
      // },
      {
        path: '/process-report',
        name: 'process-report',
        component: ProcessReport
      },
      {
        path: '/department-report',
        name: 'department-report',
        component: DepartmentReport
      },
      {
        path: '/employee-report',
        name: 'employee-report',
        component: EmployeeReport
      },
      {
        path: '/outward-report',
        name: 'outward-report',
        component: OutwardReport
      },
      {
        path: '/party-report',
        name: 'party-report',
        component: PartyReport
      },
      {
        path: '/rough-report',
        name: 'rough-report',
        component: RoughReport
      },
      {
        path: '/manager-stock-report',
        name: 'manager-stock-report',
        component: ManagerStockReport
      },
      {
        path: '/costing-notification',
        name: 'costing-notification',
        component: CostingNotification
      },
      {
        path: '/costing-detail/:id',
        name: 'costing-detail',
        component: CostingDetail
      }
    ]
  },
  {
    path: '/user/login',
    name: 'login',
    component: Login
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})
export default router
