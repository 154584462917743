<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Receive Recut</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-8" class="form-label">Scan Lot No</label>
                  <div class="input-group mt-2">

                    <input id="update-profile-form-6" type="text" tabindex="1" placeholder="Scan Barcode"
                      v-model="scan_lot_no" :class="{ 'form-control': true, 'loading': scanload }" :disabled="scanload"
                      autocomplete="off" @change="scanAndAddPack" />

                    <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack"
                      style="border-radius: 0!important">
                      <SearchIcon class="w-5 h-5 mr-2" /> Search
                    </button>
                    <button type="button" class="btn btn-primary-outline mr-auto" @click="clearSearch('')"
                      style="border-radius: 0!important">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Packet Details</h2>
            <div class="text-right">
              <button v-print="'#print-all-barcods'" class="btn btn-primary btn-sm mr-2">Print All</button>
              <button v-print="'#print-all-barcods-ls'" id="print-ls-stones" class="btn btn-primary btn-sm mr-2 hidden">Print All</button>
              <!-- <button class="btn btn-primary btn-sm mr-2" @click="set_part_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Add Planning Part</button> -->
              <!-- <button class="btn btn-primary btn-sm mr-2" @click="reset_ls_data" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Reset(LS)</button> -->
              <!-- <button class="btn btn-primary btn-sm mr-2" @click="get_ls_edit_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Edit(LS)</button> -->
            </div>
          </div>

          <div class="p-1 pb-10">
            <div class="col-span-12">
              <div style="background-color:#fff;">
                <table class="mt-3 w-full">
                  <thead>
                    <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                      <th class="py-2 text-center border">Pkt No</th>
                      <th class="py-2 text-center border">Weight</th>
                      <th class="py-2 text-center border">Current Weight</th>
                      <th class="py-2 text-center border">MK Weight</th>
                      <th class="py-2 text-center border">Exp. Wt</th>
                      <th class="py-2 text-center border">Sp</th>
                      <th class="py-2 text-center border">Cl</th>
                      <th class="py-2 text-center border">Prt</th>
                      <th class="py-2 text-center border">Ct</th>
                      <th class="py-2 text-center border">Pl</th>
                      <th class="py-2 text-center border">Sym</th>
                      <th class="py-2 text-center border">Stn</th>
                      <th class="py-2 text-center border">Fluc</th>
                      <th class="py-2 text-center border">Tn</th>
                      <th class="py-2 text-center border w-28">Print</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(packet, index) in stock_items" :key="index"
                      class="border hover:bg-gray-200 p-0 cursor-pointer">
                      <td class="py-1 font-medium text-center border">{{ packet.number }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.weight }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.cr_weight }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.mk_weight }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.polish_weight }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.shape }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.color }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.purity }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.cut }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.polish }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.symmetry }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.stone }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.fluerocent }}</td>
                      <td class="py-1 font-medium text-center border">{{ packet.tension }}</td>
                      <td class="py-1 font-medium text-center border">
                        <div class="flex justify-between items-center">
                          <input class="show-code form-check-switch mr-0 ml-3 p-0" type="checkbox"
                            v-model="packet.print_status" />
                          <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number"
                            v-if="packet.print_status == true" @click="update_print_status(packet)">
                            <PrinterIcon class="w-5 h-5" />
                          </button>
                          <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number"
                            v-if="packet.print_status == false" disabled @click="update_print_status(packet)">
                            <PrinterIcon class="w-5 h-5" />
                          </button>
                          <div class="p-0 m-0 hidden">
                            <div :id="packet.number" class="absolute top-0 left-0 p-0 m-0 mt-2">
                                    <table class="p-0 m-0 w-full text-black" border="collapse">
                                        <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                            <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                            <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="packet.mk_weight != null">PW : {{ packet.polish_weight }}</th>
                                        </tr>
                                        <tr class="text-center m-0 p-0">
                                            <th colspan="9" class="text-center">
                                                <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                                            </th>
                                        </tr>
                                        <!-- <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                            <th class="border m-auto border-black">SHP</th>
                                            <th class="border m-auto border-black">CL</th>
                                            <th class="border m-auto border-black">PRT</th>
                                            <th class="border m-auto border-black">CT</th>
                                            <th class="border m-auto border-black">PL</th>
                                            <th class="border m-auto border-black">SYM</th>
                                            <th class="border m-auto border-black">Fl</th>
                                            <th class="border m-auto border-black">TN</th>
                                            <th class="border m-auto border-black">STN</th>
                                        </tr>
                                        <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                            <th class="border m-auto border-black">{{ packet.shape }}</th>
                                            <th class="border m-auto border-black">{{ packet.color }}</th>
                                            <th class="border m-auto border-black">{{ packet.purity }}</th>
                                            <th class="border m-auto border-black">{{ packet.cut }}</th>
                                            <th class="border m-auto border-black">{{ packet.polish }}</th>
                                            <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                            <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                            <th class="border m-auto border-black">{{ packet.tension }}</th>
                                            <th class="border m-auto border-black">{{ packet.stone }}</th>
                                        </tr>
                                        <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                                            <th class="border m-auto border-black" colspan="3">L</th>
                                            <th class="border m-auto border-black" colspan="3">W</th>
                                            <th class="border m-auto border-black" colspan="3">H</th>
                                        </tr>
                                        <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                                            <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.length ? packet.length : 0) }}</th>
                                            <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.width ? packet.width : 0) }}</th>
                                            <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.height ? packet.height : 0) }}</th>
                                        </tr> -->
                                    </table>
                                    <p class="p-0 mt-1 text-black w-full text-right" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">{{packet.batch_no}}</span> / <span class="text-right">FOREVER LGD</span></p>
                                </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="hidden">
                  <div id="print-all-barcods" class="absolute left-0 top-0 p-0 m-0">

                    <div style="page-break-before: always;" class="mt-2" v-for="(packet,index) in stock_items" :key="index">
                      <table class="p-0 w-full text-black" border="collapse">
                          <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                              <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                              <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                              <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                              <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="packet.mk_weight != null">PW : {{ packet.polish_weight }}</th>
                          </tr>
                          <tr class="text-center">
                              <th colspan="9" class="text-center">
                                  <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                              </th>
                          </tr>
                          <!-- <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                              <th class="border m-auto border-black">SHP</th>
                              <th class="border m-auto border-black">CL</th>
                              <th class="border m-auto border-black">PRT</th>
                              <th class="border m-auto border-black">CT</th>
                              <th class="border m-auto border-black">PL</th>
                              <th class="border m-auto border-black">SYM</th>
                              <th class="border m-auto border-black">Fl</th>
                              <th class="border m-auto border-black">TN</th>
                              <th class="border m-auto border-black">STN</th>
                          </tr>
                          <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                              <th class="border m-auto border-black">{{ packet.shape }}</th>
                              <th class="border m-auto border-black">{{ packet.color }}</th>
                              <th class="border m-auto border-black">{{ packet.purity }}</th>
                              <th class="border m-auto border-black">{{ packet.cut }}</th>
                              <th class="border m-auto border-black">{{ packet.polish }}</th>
                              <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                              <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                              <th class="border m-auto border-black">{{ packet.tension }}</th>
                              <th class="border m-auto border-black">{{ packet.stone }}</th>
                          </tr>
                          <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                              <th class="border m-auto border-black" colspan="3">L</th>
                              <th class="border m-auto border-black" colspan="3">W</th>
                              <th class="border m-auto border-black" colspan="3">H</th>
                          </tr>
                          <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                              <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.length ? packet.length : 0) }}</th>
                              <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.width ? packet.width : 0) }}</th>
                              <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.height ? packet.height : 0) }}</th>
                          </tr> -->
                      </table>
                      <p class="p-0 text-black w-full text-right"
                          style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
                            class="text-right">{{ packet.stone }}</span> / <span class="text-right">Kodllin
                            TECHONOLOGY</span></p>
                    </div>
                  </div>
                  <div id="print-all-barcods-ls" class="absolute left-0 top-0 p-0 m-0">

                    <div style="page-break-before: always;" class="mt-2" v-for="(packet,index) in ls_items" :key="index">
                      <table class="p-0 w-full text-black" border="collapse">
                          <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                              <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                              <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                              <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                              <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="packet.mk_weight != null">PW : {{ packet.polish_weight }}</th>
                          </tr>
                          <tr class="text-center">
                              <th colspan="9" class="text-center">
                                  <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                              </th>
                          </tr>
                          <!-- <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                              <th class="border m-auto border-black">SHP</th>
                              <th class="border m-auto border-black">CL</th>
                              <th class="border m-auto border-black">PRT</th>
                              <th class="border m-auto border-black">CT</th>
                              <th class="border m-auto border-black">PL</th>
                              <th class="border m-auto border-black">SYM</th>
                              <th class="border m-auto border-black">Fl</th>
                              <th class="border m-auto border-black">TN</th>
                              <th class="border m-auto border-black">STN</th>
                          </tr>
                          <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                              <th class="border m-auto border-black">{{ packet.shape }}</th>
                              <th class="border m-auto border-black">{{ packet.color }}</th>
                              <th class="border m-auto border-black">{{ packet.purity }}</th>
                              <th class="border m-auto border-black">{{ packet.cut }}</th>
                              <th class="border m-auto border-black">{{ packet.polish }}</th>
                              <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                              <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                              <th class="border m-auto border-black">{{ packet.tension }}</th>
                              <th class="border m-auto border-black">{{ packet.stone }}</th>
                          </tr>
                          <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                              <th class="border m-auto border-black" colspan="3">L</th>
                              <th class="border m-auto border-black" colspan="3">W</th>
                              <th class="border m-auto border-black" colspan="3">H</th>
                          </tr>
                          <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                              <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.length ? packet.length : 0) }}</th>
                              <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.width ? packet.width : 0) }}</th>
                              <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.height ? packet.height : 0) }}</th>
                          </tr> -->
                      </table>
                      <p class="p-0 mt-1 text-black w-full text-right" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">{{packet.batch_no}}</span> / <span class="text-right">FOREVER LGD</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5"
      v-if="(packet_details.main_transaction_status == 1) && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && (packet_details.process_type == 2 || packet_details.process_type == 5)">
      <div class="intro-y box col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y">
          <div class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5 w-full">
              <div class="col-span-12 md:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label">Packet No :&nbsp; </label>
                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.number }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label">Issue Lot weight :&nbsp; </label>
                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.issue_weight }}
                    Ct</label>
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label">Issue To :&nbsp; </label>
                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.employee_first_name
                    + ' ' + packet_details.employee_last_name }}</label>
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label">Issue At :&nbsp; </label>
                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.created_at
                  }}</label>
                </div>
              </div>
              <div class="col-span-12 md:col-span-2"></div>
              <div class="col-span-12 md:col-span-6">
                <div class="border w-full">
                  <div style="background-color:#fff;">
                    <table class="w-full border">
                      <thead>
                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Packet No</th>
                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Rough Weight</th>
                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cal Wt</th>
                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Labur</th>
                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">1</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ packet_details.number }}</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text"
                              class="form-control w-full py-1" @keypress="$h.preventInvalidInput" disabled
                              v-model="menual_total.rough_weight"></td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"></td>
                        </tr> -->
                        <tr v-for="(item, index) in rough_items" :key="index">
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r w-12">{{ index + 1 }}</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{item.packet_no}}</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" @input="getPlanningTotal"
                              class="form-control w-full py-1 text-center" @keypress="$h.preventInvalidInput" v-model="item.rough_weight">
                          </td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text"
                              class="form-control w-full py-1 text-center" @keypress="$h.preventInvalidInput" :disabled="index == 0"
                              @change="getMajuri_details(item)" v-model="item.cal_weight"></td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text"
                              class="form-control w-full py-1 text-center" @keypress="$h.preventInvalidInput" disabled
                              v-model="item.majuri"></td>
                          <!-- <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" v-if="((index + 1) != rough_items.length && rough_items.length > 1) || rough_items.length == 1"
                              class="form-control w-full py-1 text-center" @keypress="$h.preventInvalidInput"
                              @change="getMajuri_details(item)" v-model="item.cal_weight"></td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" v-if="((index + 1) != rough_items.length && rough_items.length > 1) || rough_items.length == 1"
                              class="form-control w-full py-1 text-center" @keypress="$h.preventInvalidInput" disabled
                              v-model="item.majuri"></td> -->
                          <td>
                            <button type="button" class="text-theme-6 flex items-center" tabindex="-1" v-if="index != 0"
                              @click="removeField(item.id)">
                              <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                            </button>
                          </td>
                        </tr>
                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                          <td></td>
                          <td><button class="btn btn-primary btn-sm py-1" title="Add New Rough" @click="createNewRough">
                              <PlusIcon class="h-4 w-4" /> Rough
                            </button>
                          </td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ menual_total.weight }}</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ menual_total.ls_weight }}
                          </td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ menual_total.ls_majuri }}
                          </td>
                          <td></td>

                        </tr>
                        <tr class="border-b bg-gray-100 dark:bg-dark-1"
                          v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2">
                          <td colspan="1" class=""></td>
                          <td colspan="1" class="">Assortment Weight</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text"
                              class="form-control w-full py-1 text-center" @keypress="$h.preventInvalidInput"
                              @input="getPlanningTotal" v-model="menual_total.assortment_weight"></td>
                          <td colspan="1" class="">Ghat Weight</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{menual_total.ghat_weight}}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->

        <div class="intro-y">
          <div class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto"></h2>
            <button type="button" class="btn btn-primary btn-sm" @click="submitReturnRough">
              Take Return
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  defineComponent
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
  BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import Tabulator from 'tabulator-tables'
// import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime,
    VueBarcode
  },
  props: {
    rough_id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      packet_details: [],
      stock_items: [],
      ls_items: [],
      rough_items: [],

      rough_cnt: 0,
      user_details: [],
      menual_total: {
        rough_weight: 0,
        total_return_weight: 0,
        weight: 0,
        polish_weight: 0,
        value: 0,
        return_weight: 0,
        ghat_weight: 0,
        assortment_weight: 0,
        ls_weight: 0,
        ls_majuri: 0,
      },
      last_pack_id: '',
      scanload: false,
      scan_lot_no: '',
    }
  },
  computed: {

  },
  mounted() {

    this.user_details = this.$store.state.user.currentUser
  },

  methods: {
    async scanAndAddPack() {
      let _this = this;
      _this.clearSearch(this.scan_lot_no);
      this.scanload = true;

      //get lot details
      const data = {
        barcode: this.scan_lot_no,
      };
      let url_post = '';
      if (this.scan_lot_no != '') {
        url_post = "api/process/get_packet_details_for_recive_ls_new"
      } else {
        url_post = "api/process/get_packet_details_for_recive_ls_new"
      }
      let promise = axios({
        url: url_post,
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {

          this.scanload = false;
          this.packet_details = data.packet_details
          this.stock_items = data.stock_items
          this.rough_items = []

          this.rough_cnt += 1;
          let new_arr = {
            id: this.rough_cnt,
            packet_no: this.packet_details.number,
            packet_id: this.packet_details.id,
            rough_weight: this.packet_details.issue_weight,
            cal_weight: this.packet_details.issue_weight,
            majuri: ""
          }

          this.rough_items.push(new_arr)

          this.getMajuri_details(this.rough_items[0])

        })
        .catch((_error) => {
          this.scanload = false;
          return [];
        });
    },
    createNewRough() {
      this.rough_cnt += 1;
      let new_arr = {
        id: this.rough_cnt,
        packet_no: "New Part",
        packet_id: "",
        rough_weight: "",
        cal_weight: "",
        majuri: ""
      }

      this.rough_items.push(new_arr)
    },
    removeField(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete a Record!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          var i = this.rough_items.length;
          while (i--) {
            if (this.rough_items[i]['id'] == id) {
              this.rough_items.splice(i, 1);
            }
          }
          // this.rough_items[this.rough_items.length - 1].cal_weight = ""
          // this.rough_items[this.rough_items.length - 1].majuri = ""
          this.getPlanningTotal()
        }
      });
    },
    submitReturnRough() {
      let submit = true

      // return;

      this.rough_items.forEach(plan => {
        if (!(plan.rough_weight)) {
          this.$swal("Warning!", "New Rough Weight is Required !", "warning")
          submit = false
        }
      });
      if (submit == true) {

        this.$swal({
          title: "Are you sure?",
          text: "You are You Want To Return!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          cancelButtonText: "Cancel",

          allowOutsideClick: () => !$Swal.isLoading()
        }).then(result => {
          if (result.isConfirmed) {

            let params = {
              menual_total: this.menual_total,
              rough_items: this.rough_items,
              packet_id: this.packet_details.id,
              trans_id: this.packet_details.transaction_id,
            }

            let api_url = '';

            if (this.packet_details.process_type == 2) {
              api_url = 'api/process/return_ls_receive_new'
            } else if (this.packet_details.process_type == 5) {
              api_url = 'api/process/return_ls_receive_party'

            }
            let promise = axios({
              url: api_url,
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                headers: {
                  Accept: "application/json"
                }
              },
            });
            return promise
              .then((result) => result.data)
              .then((data) => {
                if (data.status == 'success') {
                  this.$swal("Success!", "Item Returned to Manager.", "success");
                  this.scanAndAddPack()
                  this.ls_items = data.items

                  if(this.ls_items.length > 0) {

                    setTimeout(() => {

                      document.querySelector('[id="print-ls-stones"]').click();
                    }, 200);

                  }
                } else {

                  this.$swal("Warning!", "Somthing Wants Wrong!", "warning");
                }
              })
          }
        });

      }
    },
    clearSearch(lot_no) {
      this.scan_lot_no = lot_no;
      this.packet_details = [];
      this.stock_items = [];
      this.rough_packets = [];
      this.menual_total.weight = 0;
      this.menual_total.return_weight = 0;
      this.menual_total.polish_weight = 0;
      this.menual_total.part_weight = 0;
      this.menual_total.rough_weight = 0;
      this.menual_total.value = 0;
      this.menual_total.ghat_weight = 0;
      this.menual_total.assortment_weight = 0;
      this.menual_total.ls_majuri = 0;
      this.menual_total.ls_weight = 0;
      this.scanload = false
      this.showreturndetails = false
      this.enable_return = false
    },
    update_print_status(item) {

let params = {
  packet_id: window.btoa(item.id)
}

axios({
    url: 'api/process/update_print_status',
    method: 'post',
    baseURL: BASE_URL,
    data: params,
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    config: {
        headers: {
            'Accept': 'application/json'
        }
    }
}).then((res) => {

  if(res.data.status == 'error') {

    this.$swal("Warning!", res.data.message, "warning")
  } else if(res.data.status == 'success') {

    item.print_status = false
  }
}).catch((err) => {
  this.loading = false
  this.$swal("Warning!", "Something went wrong.", "warning")
})

    },
    getMajuri_details(item) {


      let params = {
        weight: item.rough_weight,
        packet_id: this.packet_details.id
      }
      axios({
        url: 'api/process/get_ls_majuri_details',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {

        let rate = res.data.rate

        if (rate) {

          var i = this.rough_items.length;
          while (i--) {
            if (this.rough_items[i]['id'] == item.id) {
              this.rough_items[i]['majuri'] = parseFloat(rate * this.rough_items[i]['rough_weight']).toFixed(2)
            }
          }

          getPlanningTotal()
        }
      })
    },
    getPlanningTotal() {

      this.menual_total.weight = 0
      this.menual_total.return_weight = 0
      this.menual_total.ls_weight = 0
      this.menual_total.ls_majuri = 0

      if (this.packet_details.process_type == 2) {

        for (let i = 0; i < this.rough_items.length; i++) {
          if (i == (length - 1)) {
            this.last_pack_id = this.rough_items[i]['id']
          }

          this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.rough_items[i]['rough_weight'] ? this.rough_items[i]['rough_weight'] : 0)).toFixed(4)
          this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.rough_items[i]['rough_weight'] ? this.rough_items[i]['rough_weight'] : 0)).toFixed(4)
          this.menual_total.ls_weight = parseFloat(parseFloat(this.menual_total.ls_weight) + parseFloat(this.rough_items[i]['cal_weight'] ? this.rough_items[i]['cal_weight'] : 0)).toFixed(4)
          this.menual_total.ls_majuri = parseFloat(parseFloat(this.menual_total.ls_majuri) + parseFloat(this.rough_items[i]['majuri'] ? this.rough_items[i]['majuri'] : 0)).toFixed(4)

          if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_details.issue_weight)) {
            this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.rough_items[i]['rough_weight'] ? this.rough_items[i]['rough_weight'] : 0)).toFixed(4)
            this.rough_items[i]['rough_weight'] = '';
            this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
            this.getPlanningTotal()
            return;
          }

        }

        if (parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.menual_total.assortment_weight)) > parseFloat(this.packet_details.issue_weight)) {
          this.menual_total.assortment_weight = ''
          this.menual_total.ghat_weight = ''
          this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
          this.getPlanningTotal()
          return;
        }

        if (parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.menual_total.assortment_weight) + parseFloat(this.menual_total.rough_weight)) > parseFloat(this.packet_details.issue_weight)) {
          this.menual_total.assortment_weight = ''
          this.menual_total.ghat_weight = ''
          this.menual_total.rough_weight = ''
          this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
          this.getPlanningTotal()
          return;
        }

        this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.issue_weight : 0) - (parseFloat((this.menual_total.weight ? this.menual_total.weight : 0)) + parseFloat((this.menual_total.rough_weight ? this.menual_total.rough_weight : 0)) + parseFloat((this.menual_total.assortment_weight ? this.menual_total.assortment_weight : 0)))).toFixed(4)

        this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.menual_total.rough_weight ? this.menual_total.rough_weight : 0)).toFixed(4)

      }

    },
  },

})
</script>
