<template>
  <div>
    <div id="basic-non-sticky-notification-content" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium" v-if="pagedata.message">{{ pagedata.message }}</div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">New Process</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-8">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label for="crud-form-1" class="form-label">Process Name</label>
                <input
                  id="crud-form-1"
                  type="text"
                  v-model="process.process_name"
                  class="form-control w-full"
                  placeholder="Process Name"
                />
                <span class="text-primary-3" v-if="errors.process_name">{{ errors.process_name[0] }}</span>
              </div>
              <div>
                <label for="crud-form-1" class="form-label">Process Code</label>
                <input
                  id="crud-form-1"
                  type="text"
                  v-model="process.process_code"
                  class="form-control w-full"
                  placeholder="Process Code"
                />
                <span class="text-primary-3" v-if="errors.process_code">{{ errors.process_code[0] }}</span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label for="crud-form-2" class="form-label">Labour Type</label>
                <TomSelect
                        v-model="process.labour"
                        id="crud-form-2"
                        :options="{
                          placeholder: 'Select your Labour'
                        }"
                        class="w-full"
                      >
                        <option value="1">Single Time</option>
                        <option value="2">Multiple Time</option>
                      </TomSelect>
              </div>
              <div>
                <label for="crud-form-2" class="form-label">Process Type</label>
                <TomSelect
                        v-model="process.type"
                        id="crud-form-2"
                        :options="{
                          placeholder: 'Select your Labour'
                        }"
                        class="w-full"
                      >
                      <option value="rough">Rough Lot</option>
                      <option value="bunch">Rough Bunch</option>
                      <option value="makeable">Makeable</option>
                        <!-- <option value="video">Video</option> -->
                        <!-- <option value="lab">Lab</option> -->
                      </TomSelect>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Transaction Type && Short Code</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <div v-if="pagedata.is_makeable == true">
                  <TomSelect
                      v-model="process.transaction_type"
                      id="crud-form-2"
                      :options="{
                        placeholder: 'Select Type'
                      }"
                      class="w-full"
                    >
                      <option value="1">Normal</option>
                      <!-- <option value="5">Full Process</option> -->
                      <option value="6">HPHT</option>
                      <option value="7">LAB</option>
                      <option value="8">Final Polish</option>
                    </TomSelect>
                    <div class="text-primary-3" v-if="errors.transaction_type">{{ errors.transaction_type[0] }}</div>
                </div>
                <div v-else-if="pagedata.is_rough == true">
                  <TomSelect
                      v-model="process.transaction_type"
                      id="crud-form-2"
                      :options="{
                        placeholder: 'Select Type'
                      }"
                      class="w-full"
                    >
                      <option value="1">Normal</option>
                      <option value="9">LS</option>
                      <option value="2">Recut</option>
                      <option value="3">Planning</option>
                      <option value="4">Checking</option>
                      <!-- <option value="5">Full Process</option> -->
                    </TomSelect>
                    <div class="text-primary-3" v-if="errors.transaction_type">{{ errors.transaction_type[0] }}</div>
                </div>
                <div v-else>
                  <TomSelect
                      v-model="process.transaction_type"
                      id="crud-form-2"
                      disabled
                      :options="{
                        placeholder: 'Select Type'
                      }"
                      class="w-full"
                    >
                      <option value="1">Normal</option>
                      <option value="9">LS</option>
                      <option value="2">Recut</option>
                      <option value="3">Planning</option>
                      <option value="4">Checking</option>
                      <!-- <option value="5">Full Process</option> -->
                    </TomSelect>
                    <div class="text-primary-3" v-if="errors.transaction_type">{{ errors.transaction_type[0] }}</div>
                </div>
              </div>
              <div class="mt-2 sm:mt-0">
                <div class="input-group">
                  <div id="input-group-4" class="input-group-text whitespace-nowrap">Short Code</div>
                    <input
                      type="text"
                      class="form-control" v-model="process.short_code"
                      placeholder="Short Code"
                      aria-describedby="input-group-4"
                    />
                  </div>
                <div class="text-primary-3" v-if="errors.short_code">{{ errors.short_code[0] }}</div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Limit & Durtion</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <div class="input-group">
                  <div id="input-group-3" class="input-group-text">limit</div>
                    <input
                      type="number" v-model="process.limit"
                      class="form-control"
                      placeholder="Limit Issue"
                      aria-describedby="input-group-3"
                    />
                  </div>
                <div class="text-primary-3" v-if="errors.limit">{{ errors.limit[0] }}</div>
              </div>
              <div class="mt-2 sm:mt-0">
                <div class="input-group">
                  <div id="input-group-4" class="input-group-text">Days</div>
                    <input
                      type="number"
                      class="form-control" v-model="process.duration"
                      placeholder="Time Duration"
                      aria-describedby="input-group-4"
                    />
                  </div>
                <div class="text-primary-3" v-if="errors.duration">{{ errors.duration[0] }}</div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <div>Internal Bonus</div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="process.internal_bonus"
                    class="form-check-switch"
                  />
                  <button v-if="process.internal_bonus && pagedata.insernal_incer" @click="CountBonus('Internal','Plus')"><PlusSquareIcon class="w-8 h-8 ml-3 text-primary-10" /></button>
                  <button v-if="process.internal_bonus && process.internal_count != 1 && pagedata.insernal_incer" @click="CountBonus('Internal','Minus')"><MinusSquareIcon class="w-8 h-8 text-primary-3" /></button>
                </div>
              </div>
              <div class="mt-2 sm:mt-0">
                <div>External Bonus</div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="process.external_bonus"
                    class="form-check-switch"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div v-if="process.internal_bonus">
                  <div class="mt-2" v-for="(internal, index) in process.internal_bonus_rate" :key="index">
                    <div>
                      <input
                        type="number"
                        class="form-control"
                        v-model="process.internal_bonus_rate[index]"
                        :placeholder="`Internal Bonus ${index +1}`"
                        aria-describedby="input-group-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div v-if="process.external_bonus">
                  <div>
                      <input
                        type="number"
                        class="form-control"
                        v-model="process.external_bonus_rate"
                        :placeholder="`External Bonus`"
                        aria-describedby="input-group-4"
                      />
                    </div>
                  </div>
              </div>
            </div>
            </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'process'}" type="button" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </router-link>
            <button type="button" @click="CreateProcess" :disabled="pagedata.submit" class="btn btn-primary w-24">Save
            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import Toastify from 'toastify-js'
import { useRoute} from 'vue-router'
import { stringify } from 'json5'

export default defineComponent({
  setup() {
    const process = reactive({
      id: '',
      process_name: '',
      process_code: '',
      process_order: '',
      short_code: '',
      limit: null,
      duration: null,
      labour: '1',
      internal_bonus: false,
      external_bonus: false,
      type: 'rough',
      internal_count: 1,
      external_count: 1,
      transaction_type: '1',
      internal_bonus_rate: [''],
      external_bonus_rate: ''
    })
    const pagedata = reactive({
      errors: [],
      submit: false,
      is_rough: true,
      is_makeable: false,
      message: '',
      internal: false,
      external: false,
      insernal_incer: true
    })
    const errors = toRef(pagedata,'errors')
    const route = useRoute()

    function CreateProcess() {
      pagedata.submit = true
      if(route.params.id) {
        axios({
        url: 'api/master/update_process',
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false

        if(res.data.message) {
          pagedata.message = res.data.message
          setTimeout(() => {
            this.basicNonStickyNotificationToggle()
          },100)
          setTimeout(() => {
            pagedata.message = ''
          }, 3000);
          router.push({ name: 'process' })
        } else if (res.data.error) {

          pagedata.message = res.data.error
          setTimeout(() => {
            pagedata.message = ''
          }, 3000);
          setTimeout(() => {
            this.basicNonStickyNotificationToggle()
          },100)
        }

      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
          setTimeout(() => {
            pagedata.errors = []
          }, 3000);
      })
      } else {
        axios({
        url: 'api/master/add_new_process',
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {

        pagedata.submit = false

        if(res.data.message) {
          pagedata.message = res.data.message
          setTimeout(() => {
            this.basicNonStickyNotificationToggle()
          },100)
          setTimeout(() => {
            pagedata.message = ''
          }, 3000);
          router.push({ name: 'process' })
        } else if (res.data.error) {

          pagedata.message = res.data.error
          setTimeout(() => {
            pagedata.message = ''
          }, 3000);
          setTimeout(() => {
            this.basicNonStickyNotificationToggle()
          },100)
        }

      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
          setTimeout(() => {
            pagedata.errors = []
          }, 3000);
      })
      }
    }
    const basicNonStickyNotificationToggle = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    onMounted(() => {
      if(route.params.id) {
        process.id = route.params.id
        getProcess(route.params.id)
      }
    })
    function getProcess(id) {
      axios({
        url: 'api/master/get_single_process_details',
        method: 'post',
        baseURL: BASE_URL,
        data: {id: id},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {

        process.process_name = res.data.item.process_name
        process.process_code = res.data.item.process_code
        process.process_order = res.data.item.process_order
        process.short_code = res.data.item.process_short_code
        process.limit = res.data.item.mc
        process.duration = res.data.item.duration
        process.labour = String(res.data.item.labour)
        process.external_bonus_rate = res.data.item.external_bonus_rates
        process.type = res.data.item.process_type
        process.internal_bonus = res.data.item.internal_bonus == 1 ? true:false
        process.external_bonus = res.data.item.external_bonus == 1 ? true:false
        if(process.internal_bonus == 1) {
          pagedata.internal = true
          process.internal_count = res.data.item.internal_bonus_rates.length
          for (let i = 0; i < process.internal_count; i++) {
            process.internal_bonus_rate[i] = res.data.item.internal_bonus_rates[i]
          }
          process.internal_bonus_rate = res.data.item.internal_bonus_rates
        } else {
          process.internal_count = 1
          process.internal_bonus_rate = ['']
        }
        setTimeout(() => {
          process.transaction_type = String(res.data.item.transaction_type)
          pagedata.internal = false
          pagedata.external = false
        }, 100);
      })
    }
    function CountBonus(type, activity) {
      if(type == 'Internal') {
        if(activity == 'Plus') {
          process.internal_count += 1
        } else {
          process.internal_count -= 1
        }
      } else if (type == 'External') {
        if(activity == 'Plus') {
          process.external_count += 1
        } else {
          process.external_count -= 1
        }
      }
    }
    watch(() => process.type, (currentValue, oldValue) => {
      if(process.type == 'rough') {
        pagedata.is_rough = true
        pagedata.is_makeable = false
      } else if (process.type == 'makeable') {
        pagedata.is_makeable = true
        pagedata.is_rough = false
        process.transaction_type = '1'

      } else {
        pagedata.is_makeable = false
        pagedata.is_rough = false
        process.transaction_type = '1'

      }
    })
    watch(() => process.internal_count, (currentValue, oldValue) => {
      if(pagedata.internal == false) {
        if(currentValue > oldValue) {
          process.internal_bonus_rate[process.internal_bonus_rate.length] = ''
        } else {
          process.internal_bonus_rate.splice(process.internal_count,process.internal_bonus_rate.length)
        }
      }
    })
    watch(() => process.labour, (currentValue, oldValue) => {
      if(process.labour == 3 || process.labour == 4 || process.labour == 5) {
        pagedata.insernal_incer = false
        process.internal_count = 1
      } else {
        pagedata.insernal_incer = true
      }
    })
    return {
      pagedata,
      errors,
      CreateProcess,
      process,
      basicNonStickyNotificationToggle,
      CountBonus
    }
  }
})
</script>
